<template>
    <div class="contact-info-area pb-100">
        <div class="container">
            <div class="contact-info-inner">
                <h2>Have any question in mind please call or mail us</h2>
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-contact-info-box">
                            <div class="icon bg1">
                                <i class="ri-customer-service-2-line"></i>
                            </div>
                            <h3><a href="tel:(+91) 80-9054-9054">(+91) 80-9054-9054</a></h3>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-contact-info-box">
                            <div class="icon">
                                <i class="ri-earth-line"></i>
                            </div>
                            <h3><a href="mailto:hi@meander.software">hi@meander.software</a></h3>
                        </div>
                    </div>
                </div>
                <div class="lines">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactInfo'
}
</script>