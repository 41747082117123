import { createApp, h, provide } from "vue";
import { DefaultApolloClient } from "@vue/apollo-composable";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import "./assets/custom.scss";
import axios from "axios";
import VueAxios from "vue-axios";
import apolloClient from "./apollo"; // Import Apollo Client

const DEV_API_URL = "https://content.meander.software/api";
const PROD_API_URL = "https://content.meander.software/api";
const BACKEND_URL = "https://backend.meander.software";

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient); // Provide Apollo Client globally
  },
  render: () => h(App),
});

app.config.globalProperties.$baseUrl =
  process.env.NODE_ENV == "production" ? PROD_API_URL : DEV_API_URL;
app.config.globalProperties.$backendUrl = BACKEND_URL;

app.use(VueAxios, axios);
app.use(AOS.init()).use(router).mount("#app");
