<template>
    <div class="gradient-main-banner">
        <div class="container-fluid">
            <div class="gradient-main-banner-inner">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="content">
                            <span class="sub-title text-uppercase  ">#Get your 14 days free trail</span>
                            <h1>The Revolutionary App That Makes Your Life Easier Than Others App</h1>
                            <p>Discover the revolutionary app that simplifies your life like never before. Experience unparalleled convenience and efficiency with this innovative solution.</p>
                            <router-link to="/sign-in" class="default-btn">Schedule Demo</router-link>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="image">
                            <img src="../../assets/images/banner/banner-img4.png" alt="banner-img">
                        </div>
                    </div>
                </div>
                <div class="banner-shape2">
                    <img src="../../assets/images/shape/shape7.png" alt="image">
                </div>
                <div class="banner-shape9">
                    <img src="../../assets/images/shape/shape21.png" alt="image">
                </div>
                <div class="banner-shape10">
                    <img src="../../assets/images/shape/shape21.png" alt="image">
                </div>
                <div class="banner-shape11">
                    <img src="../../assets/images/shape/shape22.png" alt="image">
                </div>
                <div class="banner-shape3">
                    <img src="../../assets/images/shape/shape2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>