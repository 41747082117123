<template>
    <div class="gradient-banner-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="gradient-banner-content">
                        <h1>Manage All of Your Stuff Using a Meander Software</h1>
                        <p>Discover a powerful suite of tools designed to simplify your workflow and boost productivity.</p>
                        <form>
                            <input type="text" class="input-newsletter" placeholder="Enter Your Email Address" name="email">
                            <button type="submit" class="default-btn">Get Started</button>
                        </form>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="gradient-banner-image" data-aos="fade-up">
                        <img src="../../assets/images/banner/banner-img3.png" alt="banner-img">
                    </div>
                </div>
            </div>
        </div>
        <div class="banner-shape1"><img src="../../assets/images/shape/shape9.png" alt="image"></div>
        <div class="banner-shape2"><img src="../../assets/images/shape/shape7.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>