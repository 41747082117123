<template>
    <div>
        <Navbar />
        <PageTitle :data="{ pageTitle: 'FAQ' }" />
        <Faq />
        <Address/>
<FooterStyleFour/>
        <!-- <ContactInfo /> -->
        <!-- <FooterStyleTwo /> -->
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import Faq from '../Faq/Faq'
import ContactInfo from '../Faq/ContactInfo'
import FooterStyleTwo from '../Layout/FooterStyleTwo'
import Address from '../Contact/Address.vue'
import FooterStyleFour from "../Layout/FooterStyleFour";

export default {
    name: 'BlogPageOne',
    components: {
    Navbar,
    PageTitle,
    Faq,
    ContactInfo,
    FooterStyleTwo,
    Address,
    FooterStyleFour
}
}
</script>