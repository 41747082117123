<template>
    <div class="features-area pt-50 pb-50" >
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-content">
                        <span class="sub-title text-uppercase  ">#Key Features
</span>
                        <h2>Choose Meander Software for Customized Solutions and Advantages
</h2>
                        <p>At Meander Software, we understand that every business has unique needs, and that's why we offer customized software solutions that are tailored to meet the specific needs of each client. We take the time to understand our clients' requirements and design solutions that are scalable, secure, and aligned with their business objectives. Our transparent process ensures that our clients are involved in every stage of the development process, from planning and design to development and testing.
                              </p>
                        <div class="btn-box">
                            <a @click="openCalendly" class="default-btn">Schedule Demo</a>
                            <!-- <a href="#" class="link-btn">See All Features</a> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12" >
                    <div class="list-of-features">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-star-smile-line"></i>
                                    </div>
                                    <h3>Experienced Professionals</h3>
                                    <p> Our seasoned team guarantees exceptional service and results that exceed expectations.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-file-list-3-line"></i>
                                    </div>
                                    <h3>Tailored Solutions</h3>
                                    <p>We take a personalized approach to each project, tailoring our solutions to meet the specific needs and preferences of our clients.
</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-medal-2-line"></i>
                                    </div>
                                    <h3>High-Quality Deliverables</h3>
                                    <p>Our unwavering commitment to quality ensures top-notch solutions that meet or surpass your expectations.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-money-pound-circle-line"></i>
                                    </div>
                                    <h3>Competitive Pricing</h3>
                                    <p>We offer competitive pricing that is tailored to each project's unique requirements, ensuring that our clients receive the best value for their investment.</p>
                                </div>
                            </div>
                          
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BestFeaturesEver',
    props: {
        data: {
      type: [], // Set the prop type to be Any
      required: true,
    },
},
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>