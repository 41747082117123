<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Contact Us" />
        <Contact />
        <Address/>
 
        <FooterStyleTwo class="footer-style-two " />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Contact/PageTitle'
import Contact from '../Contact/Contact'
 
import FooterStyleTwo from '../Layout/FooterStyleFour'
import Address from '../Contact/Address.vue'

export default {
    name: 'BlogPageOne',
    components: {
    Navbar,
    PageTitle,
    Contact,
  
    FooterStyleTwo,
    Address
}
}
</script>