<template>
    <div class="features-area pt-100 pb-75" >
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-content">
                        <span class="sub-title text-uppercase  ">Integration Capabilities

</span>
                        <h2>Simplify Data Exchange with Meander One's Integration Features</h2>

                        <p>Experience effortless and efficient workflows with Meander One's robust integration capabilities. Seamlessly connect and collaborate across systems, unleashing the true potential of your applications. From data exchange to process automation, Meander One streamlines your workflows, boosts productivity, and drives business growth.

                              </p>
                        <div class="btn-box">
                            <a @click="openCalendly" class="default-btn">Schedule Demo</a>
                            <!-- <a href="#" class="link-btn">See All Features</a> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12" >
                    <div class="list-of-features">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>Effortless Integration</h3>
                                    <p> Connect Meander One with your systems effortlessly, enabling smooth data exchange and seamless functionality.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>Broad API Compatibility</h3>
                                    <p> Benefit from compatibility with a wide range of APIs across industries, empowering you to integrate diverse functionalities into your applications.
</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>Tailored Customization</h3>
                                    <p>Customize Meander One to suit your specific integration requirements and unique business needs, ensuring a tailored and optimized solution.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>Real-time Data Synchronization</h3>
                                    <p>Achieve accurate and up-to-date information across systems with real-time data synchronization, facilitating efficient decision-making and enhancing operational efficiency.</p>
                                </div>
                            </div>
                          
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IntegrationCapabilities',
    props: {
        data: {
      type: [], // Set the prop type to be Any
      required: true,
    },
},
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>