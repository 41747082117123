<template>
    <div>
        <Navbar />
        <!-- <PageTitle pageTitle="Services" /> -->
        <ChallengesAndTrack />
        <Funfacts />
        <Team />
        <SoftwareIntegrations />
        <ChallengesAndTracks />
<Challenges />
<BestFeaturesEver />
        <CustomFeatures />
<AppScreens />
<Faq />
        <FreeTrial />
        <!-- 
        <IncludedBestFeatures />
        <BestFeaturesEver />
        <AppDownload /> -->
        <FooterStyleFour />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import ChallengesAndTrack from  "../AboutOne/ChallengesAndTrack"
import ChallengesAndTracks from  "../AboutTwo/ChallengesAndTrack"
import Challenges from "../HomeOne/ChallengesAndTrack"
import Team from '../TeamOne/Team.vue'
import Partner from '../Common/Partner'
import AppDownload from "../Common/AppDownload"
import Feedback from "../AboutTwo/Feedback"
import Funfacts from "../AboutTwo/Funfacts"
import CustomFeatures from "../FeaturesTwo/CustomFeatures"
import IncludedBestFeatures from '../Services/IncludedBestFeatures'
import BestFeaturesEver from '../FeaturesOne/BestFeaturesEver'
import Blog from "../BlogOne/Blog"
import SoftwareIntegrations from "../AboutOne/SoftwareIntegrations"
import FeedbackTwo  from "../Feedback/FeedbackTwo"
import AppScreens from "../HomeOne/AppScreens";
import Faq from "../Faq/Faq"
  import FreeTrial from "../HomeOne/FreeTrial"



// import IncludedBestFeatures from '../Services/IncludedBestFeatures'
// import BestFeaturesEver from '../Services/BestFeaturesEver'
// import AppDownload from '../Services/AppDownload'
 import FooterStyleFour from '../Layout/FooterStyleFour'

export default {
    name: 'Industries',
    components: {
        Navbar,
        PageTitle,
        ChallengesAndTrack,
        IncludedBestFeatures,
        Challenges,
        Team,
        Partner,
        AppDownload,
        Feedback,
        Funfacts,
        CustomFeatures,
        BestFeaturesEver,
        Blog,
        SoftwareIntegrations,
        FeedbackTwo,
        AppScreens,
        Faq,
        FreeTrial,
        

        // Features,
        // IncludedBestFeatures,
        // BestFeaturesEver,
        // AppDownload,
        FooterStyleFour,
    }
}
</script>