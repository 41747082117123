<template>
    <div class="contact-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>Get in Touch</h2>
                <p>The IT industry offers a sea of options, from platforms, programming languages methodologies, technologies, tools, and more.</p>
            </div>
            <div class="contact-form">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <input type="text" name="name" class="form-control" id="name" placeholder="Eg: Sarah Taylor">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <input type="email" name="email" class="form-control" id="email" placeholder="hi@meander.software">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <input type="text" name="phone_number" class="form-control" id="phone_number" placeholder="Enter your phone number">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <input type="text" name="msg_subject" class="form-control" id="msg_subject" placeholder="Enter your subject">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="form-group">
                                <textarea name="message" id="message" class="form-control" cols="30" rows="6" placeholder="Enter message..."></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <button @click="sendMessage" type="submit" class="default-btn"><i class='bx bx-paper-plane'></i> Send Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
       
    </div>
</template>

<script>
export default {
    name: 'Contact',
methods: {
    sendMessage(){
        this.$toasted.show('Hello, I am a toast!', {
        theme: 'toasted-primary',
        position: 'bottom-right',
        duration: 3000,
      });
    }
}
}
</script>