<template>
    <div class="features-area pt-100 pb-75">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-3 col-lg-3 col-sm-6 col-md-4">
                    <div class="features-box">
                        <div class="icon">
                            <i class="ri-smartphone-line"></i>
                        </div>
                        <h3>User Friendly</h3>
                        <p>Most Provably best dashboard design for your business you can try.</p>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-6 col-md-4">
                    <div class="features-box">
                        <div class="icon bg2">
                            <i class="ri-award-line"></i>
                        </div>
                        <h3>Award Winning App</h3>
                        <p>Most Provably best dashboard design for your business you can try.</p>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-6 col-md-4">
                    <div class="features-box">
                        <div class="icon bg3">
                            <i class="ri-fingerprint-line"></i>
                        </div>
                        <h3>Privacy Protected</h3>
                        <p>Most Provably best dashboard design for your business you can try.</p>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-6 col-md-4">
                    <div class="features-box">
                        <div class="icon bg4">
                            <i class="ri-vip-diamond-line"></i>
                        </div>
                        <h3>Lifetime Update</h3>
                        <p>Most Provably best dashboard design for your business you can try.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>