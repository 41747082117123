<template>
    <div class="pricing-area pt-100">
        <div class="container">
            <div class="section-title">
                <span class="sub-title text-uppercase  ">PRICING TABLE</span>
                <h2>No Hidden Charge Applied, Choose Your Plan</h2>
            </div>
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" v-for="(item,index) in data[0]?.attributes?.ms_apps_pricings?.data" :key="index">
                    <div class="single-pricing-box">
                        <div class="title">
                            <h3>{{ item?.attributes?.heading }}</h3>
                            <!-- <p>Features</p> -->
                        </div>
                        <!-- <div class="price">
                            $499
                            <span>/Month</span>
                        </div> -->
                        <router-link to="/contact" class="default-btn">Learn More</router-link>
                        <ul class="features-list">
                            <li><i class="ri-check-line" v-if="item?.attributes?.li_a"></i> {{ item?.attributes?.li_a }}</li>
                            <li><i class="ri-check-line" v-if="item?.attributes?.li_b"></i>  {{ item?.attributes?.li_b }}</li>
                            <li><i class="ri-check-line" v-if="item?.attributes?.li_c"></i>  {{ item?.attributes?.li_c }}</li>
                            <li><i class="ri-check-line" v-if="item?.attributes?.li_d"></i> {{ item?.attributes?.li_d}}</li>
                            <li><i class="ri-check-line" v-if="item?.attributes?.li_e"></i>  {{ item?.attributes?.li_e}}</li>
                            <li><i class="ri-check-line" v-if="item?.attributes?.li_f"></i>  {{ item?.attributes?.li_f}}</li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-pricing-box active">
                        <div class="title">
                            <h3>Standard Features</h3>
                            <p>Features</p>
                        </div>
                        <span class="popular">
                                  </span>
                        <div class="price">
                            $799 
                            <span>/Month</span>
                        </div>
                        <router-link to="/contact" class="default-btn">Purchase Plan</router-link>
                        <ul class="features-list">
                            <li><i class="ri-check-line"></i> Advanced Search</li>
                            <li><i class="ri-check-line"></i> User Profiles</li>
                            <li><i class="ri-check-line"></i> Notifications</li>
                            <li><i class="ri-check-line"></i> Loyalty Program</li>
                            <li><i class="ri-check-line"></i> Reviews & Ratings</li>
                            <li><i class="ri-check-line"></i> 2month free Maintenance + Marketing</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-pricing-box">
                        <div class="title">
                            <h3>Premium Package Features</h3>
                            <p>Features</p>
                        </div>
                        <div class="price">
                            $1999
                             <span>/Month</span>
                        </div>
                        <router-link to="/contact" class="default-btn">Purchase Plan</router-link>
                        <ul class="features-list">
                            <li><i class="ri-check-line"></i> Premium Access</li>
                            <li><i class="ri-check-line"></i> Fast Delivery</li>
                            <li><i class="ri-check-line"></i> Custom Orders</li>
                            <li><i class="ri-check-line"></i> Recommendations</li>
                            <li><i class="ri-check-line"></i> Support: 24/7</li>
                            <li><i class="ri-check-line"></i> 2month free Maintenance + Marketing + ASO</li>
                        </ul>
                    </div>
                </div> -->
                <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="see-more-pricing color-black">
                        <router-link to="/pricing" class="link-btn">See All Pricing Plan</router-link>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="shape12"><img src="../../assets/images/shape/shape11.png" alt="shape"></div>
        <div class="shape13"><img src="../../assets/images/shape/shape15.png" alt="shape"></div>
    </div>
</template>

<script>
export default {
    name: 'Pricing',
    props:{
        data:[]
    },

    methods: {
        getdata() {
              
        }
    },

    mounted() {
        this.getdata(); // You should call this method using 'this'
    },
}
</script>
