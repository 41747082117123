<template>
    <div>
       
       
        <!-- Start Features Area -->
        <div class="features-area ptb-100 bg-F7F7FF">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title text-uppercase  ">KEY FEATURES</span>
                    <h2>Most Probably Included Best Features Ever</h2>
                </div>
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon">
                                <i class="ri-eye-line"></i>
                            </div>
                            <h3>Expert-Led Courses</h3>
                            <p>Benefit from courses led by industry experts, ensuring you receive top-tier education.</p>
 </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg2">
                                <i class="ri-stack-line"></i>
                            </div>
                            <h3>Hands-On Learning</h3>
                            <p> Experience real-world challenges through practical, hands-on training to build valuable skills.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg3">
                                <i class="ri-leaf-line"></i>
                            </div>
                            <h3>Flexible Learning Options</h3>
                            <p>Choose between online and in-person learning, catering to your preferences and schedule.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg4">
                                <i class="ri-secure-payment-line"></i>
                            </div>
                            <h3>Limitless Learning:</h3>
                            <p>Embrace a vision of limitless learning possibilities, empowering you to excel in your field.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg5">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>
                                Career Enhancement</h3>
                            <p>  Empower your career with knowledge and skills to boost confidence and creativity for enhanced success.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg6">
                                <i class="ri-pie-chart-2-line"></i>
                            </div>
                            <h3>
                                Global Quality Education: </h3>
                            <p>Join a community committed to driving innovation and positive change in various industries.</p>
                        </div>
                    </div>
                    <!-- <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
                        <div class="view-more-box">
                            <a href="#" class="default-btn">View More</a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- End Features Area -->

    </div>
</template>

<script>
export default {
    name: 'Benifits',
}
</script>