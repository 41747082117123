<template>
    <div class="app-ever-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="app-ever-content with-left-zero">
                        <h2>Most Probably You Are Getting Best App Ever</h2>
                        <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began.</p>
                        <ul class="list">
                            <li>
                                <div class="icon bg3 rounded-circle">
                                    <i class="ri-download-cloud-2-line"></i>
                                </div>
                                <h3>Free Download App</h3>
                                <p>Most provabily best you can trust on it, just log in with your mail account from play store and using whatever you want for your business.</p>
                            </li>
                            <li>
                                <div class="icon rounded-circle">
                                    <i class="ri-award-line"></i>
                                </div>
                                <h3>Trusted and Reliable</h3>
                                <p>Most provabily best you can trust on it, just log in with your mail account from play store and using whatever you want for your business.</p>
                            </li>
                            <li>
                                <div class="icon bg3 rounded-circle">
                                    <i class="ri-cloud-line"></i>
                                </div>
                                <h3>Cloud Storage</h3>
                                <p>Just log in with your mail account from play store and using whatever you want for your business purpose.</p>
                            </li>
                        </ul>
                        <div class="btn-box">
                            <router-link to="/app-download" class="default-btn">
                                Schedule Demo
                            </router-link>
                            <router-link to="/features-one" class="link-btn">
                                See All Features
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="app-ever-wrap-image">
                        <img src="../../assets/images/more-home/app-ever/app-ever-2.png" alt="image">

                        <div class="wrap-shape">
                            <img src="../../assets/images/more-home/app-ever/shape-2.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GettingBestApp',
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>