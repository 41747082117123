<template>
   <FormModal v-if="showPopup" :closePopup="closePopup" />

<div >
  <Navbar :openPopup="openPopup" />
  <PageTitle :data="{ pageTitle: 'Career' }" />
  <ChallengesAndTrack />
   <Features />  
   <Blog/>
   <AppProgress />
   <FooterStyleFour />
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
import PageTitle from "../Common/PageTitle";
import AppProgress from "../Career/AppProgress.vue";
import Features from '../Career/secoundbox.vue'
 
import ChallengesAndTrack from '../Career/firstbox.vue'
 
import Blog from '../Career/Partner.vue'
import FooterStyleFour from "../Layout/FooterStyleFour";
import FormModal from "../HomeThree/FormModal.vue";

export default {
  name: "CareerPage",
  components: {
    Navbar,
    PageTitle,
    AppProgress,
    Features,
    Blog,
    ChallengesAndTrack,
    
    
    FooterStyleFour,
    FormModal
},
data(){
    return{
        showPopup:false
    }
},
  methods: {
    openPopup(){
     this.showPopup  = true
    },
    closePopup(){
     this.showPopup= false
    },
        async gettitle() {
            document.title = " Career Opportunities";


      const metaDescription = document.querySelector(
          'meta[name="description"]'
        );

        if (metaDescription) {
          // If the <meta> tag exists, update its content
          metaDescription.content = 'description';
        } else {
          // If the <meta> tag doesn't exist, create a new one and append it to the <head> section
          const newMetaTag = document.createElement("meta");
          newMetaTag.name = "description";
          newMetaTag.content = "This is the meta description for career opportunities.";
          document.head.appendChild(newMetaTag);
        }
        }
    },
    mounted: function() { // Use a regular function here
        this.gettitle();
    }
};
</script>
