<template>
    <div class="features-area ptb-100 bg-F7F7FF">
        <div class="container">
            <div class="section-title">
                <span class="sub-title text-uppercase   text-uppercase">Our Offerings</span>
                <h2>Discover our trust-driven software solutions, Crafted for your success.</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                    <div class="single-features-item">
                        <div class="icon">
                            <i class="ri-code-box-fill"></i>
                        </div>
                        <h3>Web App Development</h3>
                        <p>Transform your ideas into dynamic web applications that not only adapt to any device but also catalyze your business's growth and prosperity.
</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                    <div class="single-features-item">
                        <div class="icon bg2">
                            <i class="ri-smartphone-fill"></i>
                        </div>
                        <h3>Mobile App Development</h3>
                        <p>Craft captivating, user-friendly mobile apps for both iOS and Android platforms, ensuring your brand's presence in the palm of your customers' hands.</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                    <div class="single-features-item">
                        <div class="icon bg3">
                            <i class="ri-terminal-box-fill"></i>
                        </div>
                        <h3>Software Integrations</h3>
                        <p>Streamline your business processes and workflows by seamlessly integrating various software systems and applications, boosting overall efficiency.</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                    <div class="single-features-item">
                        <div class="icon bg4">
                            <i class="ri-braces-fill"></i>
                        </div>
                        <h3>App Clones</h3>
                        <p>Leverage the success of existing applications while providing a unique user experience, saving time and resources in the process.
</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                    <div class="single-features-item">
                        <div class="icon bg5">
                            <i class="ri-cloud-line"></i>
                        </div>
                        <h3>Digital Marketing
</h3>
                        <p>Elevate your online presence, engage your target audience, and drive business growth through strategic digital marketing strategies.
</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                    <div class="single-features-item">
                        <div class="icon bg6">
                            <i class="ri-database-fill"></i>
                        </div>
                        <h3>Custom Software Development
</h3>
                        <p>Crafting unique, tailored software solutions to meet the specific needs of your business.
</p>
                    </div>
                </div>
                <!-- <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
                    <div class="view-more-box">
                        <router-link to="/features-one" class="default-btn">View More</router-link>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IncludedBestFeatures'
}
</script>