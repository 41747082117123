<template v-if="faq_categories.length > 0">
    <div class="faq-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <div class="faq-sidebar">
                        <ul>
                            <li v-for="faq_category in faq_categories" :key="faq_category.id" @click="selectedData=faq_category">
                                <a :class="{'active':selectedData?.slug==faq_category?.slug}">
                                    <span>{{faq_category?.name}}</span>
                                </a>
                            </li>
                           
                        </ul>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="faq-accordion">
                        <accordion>
                            <accordion-item v-for="questionData in selectedData?.faq" :key="questionData.title">
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ questionData?.title }}
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <div class="accordion-body" v-html="questionData?.description">
                                       
                                    </div>
                                </template>
                            </accordion-item>
                          
                        </accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";
export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem,
    },
    props: {
        faq_categories: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            selectedData:{},
            isLoading: false
        }
    },
      mounted() {
        if (this.faq_categories.length > 0) {
            this.selectedData = this.faq_categories[0]; // Set default category
        }
    }

}
</script>