<template>
  <FormModal v-if="showPopup" :closePopup="closePopup" />

    <div>
        <Navbar :openPopup="openPopup" />
        <!-- <PageTitle pageTitle="About Us" /> -->
        <About class="mt-40"/>
        <!-- <AboutMeander /> -->
        <ChallengesAndTrack />
        <Funfacts />
        <OurTeam />
        <SoftwareIntegrations />
        <div class="pb-3">

            <Partner  />
        </div>
        <CustomerReviews class="pt-0" />
        <Stats />
        <FooterStyleFour />
    </div>
</template>

<script>

import Navbar from '../Layout/Navbar'
 
import Funfacts from '../AboutOne/Funfacts.vue'
import ChallengesAndTrack from '../AboutOne/ChallengesAndTrack'
import Stats from '../AboutOne/Stats'
import SoftwareIntegrations from '../AboutOne/SoftwareIntegrations'
import CustomerReviews from '../Common/CustomerReviews'
import Partner from '../Common/Partner'
 import FooterStyleFour from '../Layout/FooterStyleFour'
import About from './AboutMeander/About.vue'
import OurTeam from './AboutMeander/OurTeam.vue'
import FormModal from '../HomeThree/FormModal.vue'

export default {
    name: 'AboutPageMeander',
    components: {
    Navbar,
   
    Funfacts,
    ChallengesAndTrack,
    Stats,
    SoftwareIntegrations,
    OurTeam,
    CustomerReviews,
    Partner,
    FooterStyleFour,
    About,
    FormModal
},
data(){
    return{
        showPopup:false
    }
},
methods: {
    openPopup(){
     this.showPopup  = true
    },
    closePopup(){
     this.showPopup= false
    },
        async gettitle() {
            document.title = "MeanderSoftware/About Us";
        }
    },
    mounted: function() {  
        this.gettitle();
    }
}
</script>