<template>
    <div class="funfacts-area ptb-100">
        <div class="container">
            <div class="row justify-content-center m-0">
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="funfacts-box mb-0">
                        <div class="icon">
                            <i class="ri-download-2-line"></i>
                        </div>
                        <p>Total Experience</p>
                        <h3> 9+ Years</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="funfacts-box bg1 mb-0">
                        <div class="icon">
                            <i class="ri-star-fill"></i>
                        </div>
                        <p> Upwork 98%</p>
                        <h3>Success</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="funfacts-box bg2 mb-0">
                        <div class="icon">
                            <i class="ri-global-line"></i>
                        </div>
                        <p>Projects Developed</p>
                        <h3>500+</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="funfacts-box bg3 mb-0">
                        <div class="icon">
                            <i class="ri-map-pin-user-line"></i>
                        </div>
                        <p>Team Size</p>
                        <h3>100+</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Funfacts'
}
</script>