<template>
    <div class="new-app-main-banner-gradient-area">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="new-app-main-banner-gradient-content">
                        <span class="sub-title text-uppercase  ">#Get Your 14 Days Free Trial</span>
                        <h1>The Revolutionary App That Make Your Life Easier Than Others</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id tincidunt eifend odio viverra diam aliquet donec again.</p>
                        <div class="banner-btn">
                             <button @click="openCalendly" class="default-btn">Schedule Demo</button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="new-app-main-banner-gradient-image" data-aos="fade-left" data-aos-duration="2000">
                        <img src="../../assets/images/more-home/banner/banner-3.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>