<template>
    <div class="app-progress-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="app-progress-image text-center">
                        <img src="../../assets/images/app/app-img2.png" alt="app-img">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="app-progress-content">
                        <span class="sub-title text-uppercase  ">Increase your Visibility</span>
                        <h2>Achieve Digital Success with Drawtopic Digital</h2>
                        <p>Discover Digital Success with Drawtopic Digital. Elevate your online presence and outperform your competition. Partner with us to engage your target audience and maximize ROI.</p>
                        <!--  <button @click="openCalendly" class="default-btn">Schedule Demo</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>