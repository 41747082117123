<template>
    <div class="screenshots-area bg-color ptb-100" v-if="data && data[0]?.attributes?.ms_technologies_screendesgins?.data[0]?.attributes?.screenShots?.data?.length">
        <div class="container">
            <div class="section-title">
                <span class="sub-title text-uppercase  ">{{ this.data[0]?.attributes?.ms_technologies_screendesgins?.data[0]?.attributes?.name||' SCREENS'}}</span>
                <h2>{{this.data[0]?.attributes?.ms_technologies_screendesgins?.data[0]?.attributes?.title||"Beautifully Crafted All App Screenshots"}}</h2>
            </div>
            <div class="screenshots-slides">
                <carousel
                :wrap-around="true"

                    :autoplay="5000"
                    :settings='settings'
                    :breakpoints='breakpoints'
                >
                    <slide 
                        v-for="slide in data[0]?.attributes?.ms_technologies_screendesgins?.data[0]?.attributes?.screenShots?.data" 
                        :key="slide?.id"
                    >
                        <div class="single-screenshot-item">
                            <img :src='`https://content.meander.software/${slide?.attributes?.url}`' alt="screenshots">
                             
                        </div>  
                    </slide>
                    
                    <template #addons>
                        <navigation>
                            <template #next>
                                <i class='ri-arrow-right-s-line'></i>
                            </template>
                            <template #prev>
                                <i class='ri-arrow-left-s-line'></i>
                            </template>
                        </navigation>
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Navigation  } from 'vue3-carousel';
import axios from 'axios'

import 'vue3-carousel/dist/carousel.css';

export default {
    name: 'TechnologiesScreens',
    props:{
        data: {
      type: [], // Set the prop type to be Any
      required: true,
    },
    },
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data(){
      
    return{
 

        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        }, 
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 5,
                snapAlign: 'center',
            },
        },
    }} 
   
}
</script>