<template>
  <FormModal v-if="showPopup" :closePopup="closePopup" />
  
  <div>
    <Navbar @click="handleNavbarLinkClick" :openPopup="openPopup" />
    <MainBanner v-if="bannerData"
    :data="bannerData"
    :openPopup="openPopup" />
    <About v-if="aboutData" :data="aboutData" />
    <AppScreens v-if="appData?.app_screens" :app_screens="appData?.app_screens" />
    <IncludedBestFeatures v-if="keyFeaturesData" :data="keyFeaturesData" />
    <Screenshots v-if="appData?.dashboard_screenshots?.length > 0" :dashboard_screenshots="appData?.dashboard_screenshots" />
    <BestFeaturesEver v-if="appData?.what_we_offer" :what_we_offer="appData?.what_we_offer" />
    <Video v-if="appData?.embed_video?.length > 0" :embed_video="appData?.embed_video" />
    <Pricing v-if="appData"/>
    <Team v-if="appData?.our_expertises?.length > 0" :our_expertises="appData?.our_expertises" />
    <iframe v-if="appData"
      frameborder="0"
      style="height:500px;width:99%;border:none;"
      src="https://forms.zohopublic.in/meandersoftwarecontact/form/ClientNeedsAssessmentForm/formperma/4fjH7ZBQUI7rdg6ZqA85n4ldQD4ajNkC_-RXMdrXXPs">
    </iframe>
    <AppDownloadOne v-if="whyUsData" :why_us="whyUsData" />
    <Partner v-if="appData"/>

    <ScheduleDemo v-if="scheduleDemoData" :schedule_demo="scheduleDemoData" />
    <AppReviews v-if="appData"/>
    <Faq v-if="appData?.faq_categories?.length > 0" :faq_categories="appData?.faq_categories" />
    <div class="container">
  <div class="row">

    <!-- Tech Notes -->
    <div v-if="appData?.tech_notes?.length" class="col-md-12">
      <h2 class="text-center">Tech Notes</h2>
      <div>
        <p v-for="(note, index) in appData.tech_notes" :key="'tech-' + index">
          <span v-for="(child, childIndex) in note.children" :key="'tech-child-' + childIndex">
            {{ child.text }}
          </span>
        </p>
      </div>
    </div>

    <!-- Demo Notes -->
    <div v-if="appData?.demo_notes?.length" class="col-md-12">
      <h2 class="text-center">Demo Notes</h2>
      <div>
        <p v-for="(note, index) in appData.demo_notes" :key="'demo-' + index">
          <span v-for="(child, childIndex) in note.children" :key="'demo-child-' + childIndex">
            {{ child.text }}
          </span>
        </p>
      </div>
    </div>

    <!-- Promo Notes -->
    <div v-if="appData?.promo_notes?.length" class="col-md-12">
      <h2 class="text-center">Promo Notes</h2>
      <div>
        <p v-for="(note, index) in appData.promo_notes" :key="'promo-' + index">
          <span v-for="(child, childIndex) in note.children" :key="'promo-child-' + childIndex">
            {{ child.text }}
          </span>
        </p>
      </div>
    </div>

    <!-- Version Notes -->
    <div v-if="appData?.version_notes?.length" class="col-md-12">
      <h2 class="text-center">Version Notes</h2>
      <div>
        <p v-for="(note, index) in appData.version_notes" :key="'version-' + index">
          <span v-for="(child, childIndex) in note.children" :key="'version-child-' + childIndex">
            {{ child.text }}
          </span>
        </p>
      </div>
    </div>

  </div>
</div>

    <FooterStyleFour v-if="appData"/>
  </div>
</template>

<script>
import { watchEffect } from "vue";
import { useGetAppBySlug } from "../../services/graphqlService";
import axios from "axios";
import Navbar from "../Layout/Navbar.vue";
import MainBanner from "../HomeThree/MainBanner.vue";
import About from "../AboutApp/AboutApp.vue";
import IncludedBestFeatures from "../FeaturesOne/IncludedBestFeatures.vue";
import Team from "../TeamOne/TechnologiesTeam.vue";
import BestFeaturesEver from "../FeaturesOne/AppBestFeatureEver.vue";
import AppScreens from "../HomeThree/AppScreens.vue";
import Screenshots from "../HomeFour/Screenshots.vue";
import Video from "../HomeOne/Video.vue";
import Pricing from "../HomeOne/Pricing.vue";
import AppDownloadOne from "../AppDownload/AppDownloadOne.vue";
import Partner from "../Common/Partner.vue";
import Faq from "../Faq/Faq.vue";
import ScheduleDemo from "../Common/AppDownload.vue";
import FooterStyleFour from "../Layout/FooterStyleFour.vue";
import AppReviews from "./AppReviews.vue";
import FormModal from "../HomeThree/FormModal.vue";

export default {
  name: "AppDetail",
  components: {
    Navbar,
    MainBanner,
    About,
    IncludedBestFeatures,
    Team,
    BestFeaturesEver,
    AppScreens,
    Screenshots,
    Video,
    Pricing,
    AppDownloadOne,
    Partner,
    Faq,
    ScheduleDemo,
    FooterStyleFour,
    AppReviews,
    FormModal,
  },
  data() {
    return {
      appData: null,
      blogs: [],
      isLoading: false,
      showPopup: false,
    };
  },
  async mounted() {
    this.fetchAppData();
    this.getBlogs();
  },
  computed: {
    bannerData() {
      if (!this.appData) return null;
      return {
        name: this.appData?.name,
        title: this.appData?.title,
        description: this.appData?.description,
        screenshot_1: this.appData?.screenshot_1,
        screenshot_2: this.appData?.screenshot_2,
      };
    },
    aboutData() {
      if (!this.appData) return null;
      return {
        about_app: this.appData.about_app,
        circle_image: this.appData.circle_image,
      };
    },
    keyFeaturesData(){
      if (!this.appData) return null;
      return {
        features: this.appData?.key_features?.features,
        title: this.appData.key_features_title,
      };
    },
    whyUsData(){
      if (!this.appData) return null;
      return {
        title: this.appData?.why_us?.title,
        description: this.appData?.why_us?.description,
        image: this.appData?.why_us?.image,

      };
    },
    scheduleDemoData(){
      if (!this.appData) return null;
      return {
        title: this.appData?.schedule_demo?.title,
        description: this.appData?.schedule_demo?.description,
        image: this.appData?.schedule_demo?.image,
      };
    },
  },
  watch: {
    "$route.params.slug": {
      immediate: true,
      handler() {
        this.fetchAppData();
      },
    },
  },
  methods: {
    openPopup() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
    fetchAppData() {
      const slug = this.$route.params.slug;
      const { result, onError } = useGetAppBySlug(slug);

      watchEffect(() => {
        if (result.value?.apps?.length) {
          this.appData = result.value.apps[0];
        }
      });

      onError((error) => {
        console.error("Error fetching app by slug:", error);
      });
    },
    async changeData(slug) {
      await this.$router.push({ name: "app-detail", params: { slug } });
    },
    async getBlogs() {
      try {
        const response = await axios.get(
          `${this.$baseUrl}/blogs?pagination[limit]=10&populate=*`
        );
        this.blogs = response.data.data;
      } catch (error) {
        this.blogs = [];
        console.error("Failed to fetch blogs:", error);
      }
    },
    async handleNavbarLinkClick(event) {
      this.isLoading = true;
      if (event?.target?.href?.includes("app-detail")) {
        await this.fetchAppData();
      }
      this.isLoading = false;
    },
    formatDate(dateString) {
      return new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(
        new Date(dateString)
      );
    },
  },
};
</script>
