<template>
    <div class="features-area pt-100 pb-75" >
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-content">
                        <span class="sub-title text-uppercase  ">Why Choose Meander Training

</span>
<h2>
    Choose Meander Training for Software Skills That Shine
</h2>
                         
                        <p>Discover why Meander Training is your ideal partner for skill development in the software industry. Explore our exceptional features. Experience exceptional features tailored to help you succeed. Explore the difference today.</p>
                        <div class="btn-box">
                            <a @click="openCalendly" class="default-btn">Schedule Demo</a>
                            <!-- <a href="#" class="link-btn">See All Features</a> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12" >
                    <div class="list-of-features">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>Industry-Seasoned Instructors</h3>
                                    <p>  Learn from professionals with vast experience, ensuring top-notch software education.
</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>Practical Learning Environment</h3>
                                    <p>Immerse yourself in real-world scenarios for hands-on skill development and effective problem-solving.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>Customized Learning Paths</h3>
                                    <p>Tailor your educational journey to align with your career goals, ensuring relevance and efficiency.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>Global Learning Network</h3>
                                    <p>Join a worldwide community committed to innovation and positive change within the software industry.</p>
                                </div>
                            </div>
                          
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BestFeaturesEver',
    props: {
        data: {
      type: [], // Set the prop type to be Any
      required: true,
    },
},
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>