<template>
     <FormModal v-if="showPopup" :closePopup="closePopup" />

<div >
  <Navbar :openPopup="openPopup" />
        <PageTitle2    :data="{ pageTitle: 'Our Process' }" />
       <!-- <OurProcess /> -->
       <CustomFeatures />
       <HowItWorks/>
       <!-- <BestFeaturesEver /> -->
       <Features/>
       <AppScreens />
       <!-- <ChallengesAndTrack /> -->
       <Partner style="margin-bottom: 70px;" />
       <SoftwareIntegrations />
       <!-- <SoftwareIntegrations /> -->
<!-- <Steps/> -->
        <FooterStyleFour />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle2 from '../Common/PageTitle2'
// import OurProcess from "../HowItWorks/OurProcess"
 import FooterStyleFour from '../Layout/FooterStyleFour'
 import CustomFeatures from "../FeaturesTwo/CustomFeatures.vue";
 import SoftwareIntegrations from "../AboutOne/SoftwareIntegrations.vue"
 import AppScreens from "../HomeOne/AppScreens";
 import ChallengesAndTrack from  "../AboutOne/ChallengesAndTrack"
// import Steps from './OurProcess/Steps.vue';
import Features from './OurProcess/Features.vue';

import FormModal from '../HomeThree/FormModal.vue';
import HowItWorks from '../HowItWorks/HowItWorks.vue';
import Partner from '../Common/Partner.vue';

export default {
    name: 'OurProcessPage',
    components: {
    Navbar,
            PageTitle2,
    //    OurProcess,
    CustomFeatures,
    // BestFeaturesEver,
    SoftwareIntegrations,
    AppScreens,
    ChallengesAndTrack,
    FooterStyleFour,
    // Steps,
    FormModal,
    HowItWorks,
    Features,
    Partner
},
data(){
    return{
        showPopup:false
    }
},
    methods: {
        openPopup(){
     this.showPopup  = true
    },
    closePopup(){
     this.showPopup= false
    },
        async gettitle() {
            document.title = " Our Development Approach";
        }
    },
    mounted: function() { // Use a regular function here
        this.gettitle();
    }
}
</script>