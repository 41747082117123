<template>
    <div>
        <ComingSoon />
    </div>
</template>

<script>
import ComingSoon from '../ComingSoon/ComingSoon'

export default {
    name: 'ComingSoonPage',
    components: {
        ComingSoon,
    }
}
</script>