<template>
    <div class="main-banner">
        <div class="container">
            <div class="content">
                <span class="sub-title text-uppercase  ">CRM Simplified</span>
                <h1>Elevate Your Sales and Service with Doers Online: The Game-Changing CRM Software
</h1>
<button @click="openCalendly" class="default-btn">Schedule Demo</button>
                <img src="../../assets/images/banner/banner-app3.png" alt="image">
            </div>
        </div>
        <div class="divider"></div>
        <div class="banner-shape3">
            <img src="../../assets/images/shape/shape2.png" alt="image">
        </div>
        <div class="banner-shape8">
            <img src="../../assets/images/shape/shape11.png" alt="image">
        </div>
        <div class="banner-shape7">
            <img src="../../assets/images/shape/shape11.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppSummary',
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>