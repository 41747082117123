import { gql } from "graphql-tag";
import { useQuery } from "@vue/apollo-composable";

export const GET_APP_BY_SLUG = gql`
  query ($slug: String!) {
    apps(filters: { slug: { eq: $slug } }) {
       name
    title
    slug
    description
    about_app {
      title
      description
      additional_info {
        title
        description
        image {
          url
        }
      }
    }
    screenshot_1 {
      url
    }
    screenshot_2 {
      url
    }
    our_expertises {
      name
      logo {
    url
      }
    }
    partners {
      name
      logo {
        url
      }
    }
    categorories {
      slug
      name
    }
    faq_categories {
      slug
      name
      faq {
        title
        description
      }
    }
    why_us {
      title
      description
      image {
        url
      }
    }
    schedule_demo {
      title
      description
      image {
        url
      }
    }
    embed_video
    what_we_offer {
      title
      description
      advantages {
        title
        description
      }
    }
dashboard_screenshots {
  url
}
key_features {
  title
features {
  title
  description
}
}
app_screens {
  url
}
clone_synonyms
alias_synonyms
keywords
    circle_image {
  url
}
tech_notes
demo_notes
demo_notes
version_notes
  }
  
}
`;

export const GET_APP_CATEGORIES = gql`
query BusinessModelCategories {
  businessModelCategories {
    name
    slug
    business_models {
      name
      slug
    }
  }
}`

export function useGetAppByCategorySlug() {
  return useQuery(GET_APP_CATEGORIES);
}

export function useGetAppBySlug(slug) {
  return useQuery(GET_APP_BY_SLUG, { slug });
}

// // Query to Get All Apps
// export const GET_ALL_APPS = gql`
//   query {
//     apps {
//       name
//       title
//       slug
//       description
//       }
//       circle_image {
//         url
//       }
//     }
//   }
// `;

// export function useGetAllApps() {
//   return useQuery(GET_ALL_APPS);
// }
