<template>
    <div class="app-download-area " v-if="why_us">
        <div class="container">
            <div class="app-download-inner">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="app-download-content">
                            <span class="sub-title text-uppercase  ">{{why_us?.title ||"WHY US"}}</span>
                            <h2>{{"Why Meander Software To Create your App?"}} </h2>
                            <p>{{why_us?.description ||"Choose Meander software for your app if you seek an easy-to-use, cost-effective, and potentially no-code/low-code solution, but evaluate it against your specific project's needs before making a decision."}}</p>
                         
                            <button @click="openCalendly" class="default-btn">Get in Touch</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="app-download-image" data-aos="fade-up">
                            <img v-if="why_us && why_us?.image?.url" 
     :src="$backendUrl + why_us?.image?.url" 
     alt="image" loading="lazy">
                            <img  v-else  src="../../assets/images/app/app-img3.png" alt="app-img" loading="eager" />
                        </div>
                    </div>
                </div>
                <div class="shape5">
                    <img src="../../assets/images/shape/shape4.png" alt="shape4" loading="eager" />
                </div>
                <div class="lines">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppDownloadOne',
    props: {
 
        why_us: {
      type: Object,
      required: true,
      default: () => ({ title: "", description: "", image: { image: {url:""} } }),

    }
  },
    methods:{
        openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
    }
}
</script>