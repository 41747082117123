<template>
  <div>
    <Navbar />
    <div class="blog-area ptb-100">
      <div class="container">
        <Preloader v-if="loading" />
        <div v-else class="row">
          <div class="col-lg-4 col-md-12">
            <aside class="widget-area left-sidebar">
              <div class="widget widget_search">
                <form class="search-form" @submit.prevent>
                  <label>
                    <input
                      type="search"
                      v-model="search"
                      class="search-field"
                      placeholder="Search..."
                    />
                  </label>
                  <button @click="searchApp(search)">
                    <i class="ri-search-2-line"></i>
                  </button>
                </form>
              </div>
              <div class="widget widget_meander_software_posts_thumb">
                <h3 class="widget-title">Popular Apps</h3>
                <article class="item" v-for="(data) in appDATA?.data" :key="data.id">
                  <router-link :to="`/app-detail/${data?.slug}`" class="thumb">
                    <span
                      class="fullimage cover"
                      :style="{ backgroundImage: 'url(' + $backendUrl + data?.circle_image?.url + ')' }"
                      role="img"
                    ></span>
                  </router-link>
                  <div class="info">
                    <h4 class="title usmall">
                      <router-link :to="`/app-detail/${data?.slug}`">
                        {{ data?.title }}
                      </router-link>
                    </h4>
                    <span class="date">
                      <i class="ri-calendar-2-fill"></i>{{ dateFormat(data?.updatedAt) }}
                    </span>
                  </div>
                </article>
              </div>
            </aside>
          </div>
          <div class="col-lg-8 col-md-12">
            <div class="row justify-content-center">
              <!-- No Data Message -->
              <div v-if="!appDATA?.data || appDATA.data.length === 0" class="col-12 text-center">
                <p>No data found.</p>
              </div>
              <!-- Main Data -->
              <div class="col-lg-6 col-md-6" v-for="(data) in appDATA?.data" :key="data.id">
                <div class="single-blog-post">
                  <div class="image">
                    <router-link :to="`/app-detail/${data?.slug}`" class="d-block">
                      <img
                        style="height:250px;"
                        :src="$backendUrl + data?.circle_image?.url"
                        alt="blog"
                      />
                    </router-link>
                  </div>
                  <div class="content">
                    <ul class="meta">
                      <li>
                        <i class="ri-time-line"></i> {{ dateFormat(data?.updatedAt) }}
                      </li>
                    </ul>
                    <h3>
                      <router-link :to="`/app-detail/${data?.slug}`">
                        {{ data?.title }}
                      </router-link>
                    </h3>
                  </div>
                </div>
              </div>
              <!-- Pagination -->
              <div v-if="total > pageSize" class="col-lg-12 col-md-12">
                <div class="pagination-area">
                  <div class="nav-links">
                    <!-- Previous Page -->
                    <span
                      v-if="currentPage > 1"
                      class="page-numbers prev cursor-pointer"
                      @click="changePage(currentPage - 1)"
                    >
                      <i class="ri-arrow-left-line"></i>
                    </span>
                    <!-- Page Numbers -->
                    <span
                      v-for="page in totalPages"
                      :key="page"
                      :class="['page-numbers', { current: page === currentPage }]"
                      @click="changePage(page)"
                    >
                      {{ page }}
                    </span>
                    <!-- Next Page -->
                    <span
                      v-if="currentPage < totalPages"
                      class="page-numbers next cursor-pointer"
                      @click="changePage(currentPage + 1)"
                    >
                      <i class="ri-arrow-right-line"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterStyleFour />
  </div>
</template>

<style>
.activeclass:active {
  color: brown;
}
</style>

<script>
import axios from "axios";
import Navbar from "../Layout/Navbar";
import FooterStyleFour from "../Layout/FooterStyleFour";
import Preloader from '../Layout/Preloader'

export default {
  name: "TemplatesAndThemes",
  components: {
    Navbar,
    FooterStyleFour,
    Preloader
  },
  data() {
    return {
      relatedApps: [],
      appDATA: [],
      search: "",
      filteredApps: [],
      matchedPerson: [],
      slugToMatch: "",
      currentPage: 1,
      pageSize: 9, // Requested page size; adjust if needed
      total: 0,
      totalPages: 0, // Will be set based on the response
      loading: false,
      errorMessage: "",
    };
  },
  methods: {
    async getApp() {
      this.loading = true;
      this.errorMessage = "";
      try {
        const response = await axios.get(
          `${this.$backendUrl}/api/apps?populate=circle_image&pagination[page]=${this.currentPage}&pagination[pageSize]=${this.pageSize}`
        );
        this.appDATA = response.data;
        const { pagination } = response.data.meta;
        this.total = pagination.total;
        this.totalPages =
          pagination.pageCount || Math.ceil(this.total / this.pageSize);
      } catch (error) {
        console.error(error);
        this.errorMessage =
          "There was a problem loading the data. Please try again later.";
      } finally {
        this.loading = false;
      }
    },
    async searchApp(searchTerm) {
      this.loading = true;
      this.errorMessage = "";
      try {
        let url = `${this.$backendUrl}/api/apps?populate=circle_image`;
        if (searchTerm.trim()) {
          url += `&filters[$or][0][title][$contains]=${encodeURIComponent(
            searchTerm
          )}`;
          url += `&filters[$or][1][name][$contains]=${encodeURIComponent(
            searchTerm
          )}`;
          url += `&filters[$or][2][clone_synonyms][$contains]=${encodeURIComponent(
            searchTerm
          )}`;
          url += `&filters[$or][3][alias_synonyms][$contains]=${encodeURIComponent(
            searchTerm
          )}`;
          url += `&filters[$or][4][keywords][$contains]=${encodeURIComponent(
            searchTerm
          )}`;
          url += `&filters[$or][5][description][$contains]=${encodeURIComponent(
            searchTerm
          )}`;
        }
        const response = await axios.get(url);
        this.appDATA = response.data;
        const { pagination } = response.data.meta;
        this.total = pagination.total;
        this.totalPages =
          pagination.pageCount || Math.ceil(this.total / this.pageSize);
      } catch (error) {
        console.error(error);
        this.errorMessage =
          "There was a problem loading the search results. Please try again later.";
      } finally {
        this.loading = false;
      }
    },
    changePage(page) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.currentPage = page;
      // Update the query parameter in the URL
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, page },
      });
      // Fetch new data based on current search or default data
      if (this.search.trim()) {
        this.searchApp(this.search);
      } else {
        this.getApp();
      }
    },
    randomNumber() {
      return Math.floor(Math.random() * 100);
    },
    dateFormat(date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString("en-US", options);
    },
  },
  watch: {
    // Watch the "page" query parameter in the URL
    "$route.query.page"(newPage) {
      const page = parseInt(newPage);
      if (!isNaN(page) && page > 0 && page !== this.currentPage) {
        this.currentPage = page;
        // Refetch data (respecting search if applicable)
        if (this.search.trim()) {
          this.searchApp(this.search);
        } else {
          this.getApp();
        }
      }
    },
  },
  async mounted() {
    // Check if there's a "page" query parameter in the URL and use it
    if (this.$route.query.page) {
      const page = parseInt(this.$route.query.page);
      if (!isNaN(page) && page > 0) {
        this.currentPage = page;
      }
    }
    await this.getApp();
  },
};
</script>
