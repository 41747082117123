<template>
    <div class="app-download-area pb-100" >
        <div class="container">
            <div class="app-download-inner bg-gray">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="app-download-content">
                            <span class="sub-title text-uppercase  ">{{data[0]?.attributes?.ms_services_schedule_demos?.data[0]?.attributes?.name||"DOWNLOADs APP"}}</span>
                            <h2>{{data[0]?.attributes?.ms_services_schedule_demos?.data[0]?.attributes?.title||"Experience Our Product in Real Time"}}</h2>
                            <p>{{data[0]?.attributes?.ms_services_schedule_demos?.data[0]?.attributes?.description||"Unlock the full potential of our solution with a personalized demo. Schedule your session today and discover how our product can transform your experience."}}</p>
                            <!-- <div class="btn-box">
                                <a href="#" class="playstore-btn" target="_blank">
                                    <img src="../../assets/images/play-store.png" alt="image">
                                    Get It On
                                    <span>Google Play</span>
                                </a>
                                <a href="#" class="applestore-btn" target="_blank">
                                    <img src="../../assets/images/apple-store.png" alt="image">
                                    Download on the
                                    <span>Apple Store</span>
                                </a>
                            </div> -->
                        </div>
                        <div class="btn-box mt-5 ">
                            <a @click="openCalendly" class="default-btn text-uppercase">Schedule Demo</a>
                            <!-- <a href="#" class="link-btn">See All Features</a> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="app-download-image" data-aos="fade-up">
                            <img src="../../../assets/images/app/app-img4.png" alt="app-img">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServiceScheduleDemo',
    props:{
        data:[]
    },
    methods:{
        openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    },
    }
}
</script>