<template>
     <FormModal v-if="showPopup" :closePopup="closePopup" />

<div >
  <Navbar :openPopup="openPopup" />
        <!-- <PageTitle pageTitle="Meander ONE" /> -->
        <MainBanner />
<About />
<KeyFeatures/>
<!-- <CustomFeatures /> -->
<!-- <Team /> -->
<Technology/>
<WhyChooseUs/>
<!-- <MeanderFeatures /> -->
<!-- <Screenshots /> -->
<!-- <HowItWorks /> -->
<IntegrationCapabilities/>
<!-- <BestFeaturesEver /> -->
<TeamTwo />
<!-- <Video /> -->
<AppDownloadOne />
<Partner />
<Address/>
<!-- <ContactInfo /> -->
<AppDownload />
<!-- <Gallery /> -->
<iframe frameborder="0" style="height:500px;width:99%;border:none;" src='https://forms.zohopublic.in/meandersoftwarecontact/form/ClientNeedsAssessmentForm/formperma/4fjH7ZBQUI7rdg6ZqA85n4ldQD4ajNkC_-RXMdrXXPs'></iframe>

 
        <FooterStyleFour />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
// import PageTitle from '../Common/PageTitle'
import MainBanner from "./MainBanner.vue"
import About from "./About.vue"
 
import TeamTwo from '../TeamTwo/Team.vue'
 
import AppDownloadOne from "../AppDownload/AppDownloadOne"
import Partner from "../Common/Partner"
 
import AppDownload from "../Common/AppDownload"
 
 import FooterStyleFour from '../Layout/FooterStyleFour'
import KeyFeatures from './KeyFeatures.vue';
import WhyChooseUs from './WhyChooseUs.vue';
import IntegrationCapabilities from './IntegrationCapabilities.vue';
import FormModal from '../HomeThree/FormModal.vue';
import Address from '../Contact/Address.vue';
import Technology from './Technology.vue'





export default {
    name: 'MeanderOne',
    components: {
    Navbar,
    // PageTitle,
    MainBanner,
    About,
    
    TeamTwo,
 
    AppDownloadOne,
    Partner,
 
    AppDownload,
  
    FooterStyleFour,
    KeyFeatures,
    WhyChooseUs,
    IntegrationCapabilities,
    FormModal,
    Address,
    Technology
},
data(){
    return{
        showPopup:false
    }
},
methods:{
    openPopup(){
     this.showPopup  = true
    },
    closePopup(){
     this.showPopup= false
    },
}
}
</script>