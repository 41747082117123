<template>
    <div class="pricing-area bg-gradient-color ptb-100">
        <div class="container">
            <div class="pricing-tabs">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-12">
                        <div class="pricing-section-title">
                            <span class="sub-title text-uppercase  ">Compare table</span>
                            <h2>Our Services vs. Competitors</h2>
                            <div class="tabs">
                                <ul class="tabs__header">
                                    <li 
                                        class="tabs__header-item" 
                                        v-for="tab in tabs" 
                                        v-on:click="selectTab (tab.id)" 
                                        v-bind:class="{ 'active': activeTab == tab.id }"
                                        :key="tab.id"
                                    >
                                        {{tab.title}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-12">
                        <div class="tab-content">
                            <div class="tabs__container">
                                <div 
                                    class="tabs__list"
                                    ref='tabsList'
                                >
                                    <div 
                                        class="tabs__list-tab"
                                        v-for="tab in tabs"
                                        v-bind:class="{ 'active': activeTab == tab.id }"
                                        :key="tab.id"
                                    >
                                        <div class="row justify-content-center">
                                            <div 
                                                class="col-lg-6 col-md-6 col-sm-6"
                                                v-for="pricing in tab.pricingBox"
                                                :key="pricing.id"
                                            >
                                                <div class="single-pricing-table">
                                                    <div class="title">
                                                        <h3>{{pricing.title}}</h3>
                                                        <p>{{pricing.desc}}</p>
                                                    </div>
                                                    <!-- <div class="price">
                                                        ${{pricing.price}} <span>/{{pricing.period}}</span>
                                                    </div> -->
                                                    <router-link to="/contact" class="default-btn">
                                                    Explore
                                                    </router-link>
                                                    <ul class="features-list">
                                                        <li
                                                            v-for="features in pricing.featuresList"
                                                            :key="features.id"
                                                        >
                                                            <i :class="features.icon"></i> 
                                                            {{features.list}}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-12 col-md-12">
                        <div class="see-more-pricing">
                            <router-link to="/pricing" class="link-btn">See All Pricing Plan</router-link>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="shape7"><img src="../../assets/images/shape/shape6.png" alt="shape" loading="eager" /></div>
        <div class="shape8"><img src="../../assets/images/shape/shape7.png" alt="shape" loading="eager" /></div>
    </div>
</template>

<script>
export default {
    name: 'Pricing',
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
            tabs: [
                {
                    id: 1,
                    title: 'Custom',
                    pricingBox: [
                        {
                            id: 1,
                            title: 'Our Development Services ',
                            price: '49',
                            period: 'Custom Plan',
                            featuresList: [
                                      
                                {
                                    id: 1,
                                    icon: 'ri-check-line',
                                    list: 'User-Centric Design',
                                },
                                {
                                    id: 2,
                                    icon: 'ri-check-line',
                                    list: 'Advanced Search',
                                },
                                {
                                    id: 3,
                                    icon: 'ri-check-line',
                                    list: 'Loyalty Program Integration',
                                },
                                {
                                    id: 4,
                                    icon: 'ri-check-line',
                                    list: 'Recommendation Engine ',
                                },
                                {
                                    id: 5,
                                    icon: 'ri-check-line',
                                    list: 'Customization ',
                                },
                                {
                                    id: 6,
                                    icon: 'ri-check-line',
                                    list: '24/7 Customer Support ',
                                },
                            ],
                        },
                        {
                            id: 2,
                            title: 'Competitiors',
                            price: '69',
                            period: 'Custom plan',
                            featuresList: [
                                {
                                    id: 1,
                                    icon: 'ri-close-line',
                                    list: 'User-Centric Design ',
                                },
                                {
                                    id: 2,
                                    icon: 'ri-check-line',
                                    list: 'Advanced Search',
                                },
                                {
                                    id: 3,
                                    icon: 'ri-close-line',
                                    list: 'Loyalty Program Integration',
                                },
                                {
                                    id: 4,
                                    icon: 'ri-close-line',
                                    list: 'Recommendation Engine',
                                },
                                {
                                    id: 5,
                                    icon: 'ri-close-line',
                                    list: 'Customization',
                                },
                                {
                                    id: 6,
                                    icon: 'ri-check-line',
                                    list: '24/7 Customer Support',
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 2,
                    title: 'Subscription',
                    pricingBox: [
                        {
                            id: 1,
                            title: 'Our Development Services ',
                            price: '69',
                            period: 'Subscripition Plan',
                            featuresList: [
                                {
                                    id: 1,
                                    icon: 'ri-check-line',
                                    list: 'SEO Optimization',
                                },
                                {
                                    id: 2,
                                    icon: 'ri-check-line',
                                    list: 'Maintenance',
                                },
                                {
                                    id: 3,
                                    icon: 'ri-check-line',
                                    list: '24/7 Support',
                                },
                                {
                                    id: 4,
                                    icon: 'ri-check-line',
                                    list: 'Marketing Package  ',
                                },
                                {
                                    id: 5,
                                    icon: 'ri-check-line',
                                    list: 'Monthly Subscription Packaging  ',
                                },
                                // {
                                //     id: 6,
                                //     icon: 'ri-close-line',
                                //     list: 'Live Support',
                                // },
                            ],
                        },
                        {
                            id: 2,
                            title: 'Competitors',
                            price: '89',
                            period: 'Subscripition Plan',
                            featuresList: [
                                {
                                    id: 1,
                                    icon: 'ri-close-line',
                                    list: 'SEO Optimization',
                                },
                                {
                                    id: 2,
                                    icon: 'ri-close-line',
                                    list: 'Maintenance',
                                },
                                {
                                    id: 3,
                                    icon: 'ri-check-line',
                                    list: '24/7 Support',
                                },
                                {
                                    id: 4,
                                    icon: 'ri-close-line',
                                    list: 'Marketing Package',
                                },
                                {
                                    id: 5,
                                    icon: 'ri-close-line',
                                    list: 'Monthly Subscription Packaging',
                                },
                                // {
                                //     id: 6,
                                //     icon: 'ri-close-line',
                                //     list: 'Live Support',
                                // },
                            ],
                        },
                    ],
                },
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    },
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    }
}
</script>