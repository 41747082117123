<template>
    <div>
        <!-- Start Features Area -->
        <div class="features-area pt-100 pb-75">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                        <div class="single-features-box">
                            <div class="icon">
                                <i class="ri-smartphone-line"></i>
                            </div>
                            <h3>User Friendly</h3>
                            <p>Most provably best dashboard design for your business you can try.</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                        <div class="single-features-box">
                            <div class="icon bg2">
                                <i class="ri-award-line"></i>
                            </div>
                            <h3>Award Winning App</h3>
                            <p>Most provably best dashboard design for your business you can try.</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                        <div class="single-features-box">
                            <div class="icon bg3">
                                <i class="ri-fingerprint-line"></i>
                            </div>
                            <h3>Privacy Protected</h3>
                            <p>Most provably best dashboard design for your business you can try.</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                        <div class="single-features-box">
                            <div class="icon bg4">
                                <i class="ri-vip-diamond-line"></i>
                            </div>
                            <h3>Lifetime Update</h3>
                            <p>Most provably best dashboard design for your business you can try.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Features Area -->

        <!-- Start Features Area -->
        <div class="features-area ptb-100 bg-F7F7FF">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title text-uppercase  ">KEY FEATURES</span>
                    <h2>Most Probably Included Best Features Ever</h2>
                </div>
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon">
                                <i class="ri-eye-line"></i>
                            </div>
                            <h3>High Resolution</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg2">
                                <i class="ri-stack-line"></i>
                            </div>
                            <h3>Retina Ready Screen</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg3">
                                <i class="ri-leaf-line"></i>
                            </div>
                            <h3>Easy Editable Data</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg4">
                                <i class="ri-secure-payment-line"></i>
                            </div>
                            <h3>Fully Secured</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg5">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>Cloud Storage</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg6">
                                <i class="ri-pie-chart-2-line"></i>
                            </div>
                            <h3>Responsive Ready</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <!-- <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
                        <div class="view-more-box">
                            <a href="#" class="default-btn">View More</a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- End Features Area -->

        <!-- Start Features Area -->
        <div class="features-area pt-100 pb-75">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                        <div class="features-box">
                            <div class="icon">
                                <i class="ri-smartphone-line"></i>
                            </div>
                            <h3>User Friendly</h3>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                        <div class="features-box">
                            <div class="icon bg2">
                                <i class="ri-award-line"></i>
                            </div>
                            <h3>Award Winning App</h3>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                        <div class="features-box">
                            <div class="icon bg3">
                                <i class="ri-fingerprint-line"></i>
                            </div>
                            <h3>Privacy Protected</h3>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                        <div class="features-box">
                            <div class="icon bg4">
                                <i class="ri-vip-diamond-line"></i>
                            </div>
                            <h3>Lifetime Update</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Features Area -->

        <!-- Start Features Area -->
        <div class="features-area pb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="features-content">
                            <span class="sub-title text-uppercase  ">KEY FEATURES</span>
                            <h2>Most Probably Included Best Features Ever</h2>
                            <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began.</p>
                            <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want chance its just began.</p>
                            <div class="btn-box">
                                <a @click="openCalendly" class="default-btn">Schedule Demo</a>
                                <a href="#" class="link-btn">See All Features</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="features-lists">
                            <ul>
                                <li>
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>High Resolution</h3>
                                    <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                                </li>
                                <li>
                                    <div class="icon bg2">
                                        <i class="ri-stack-line"></i>
                                    </div>
                                    <h3>Retina Ready Screen</h3>
                                    <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                                </li>
                                <li>
                                    <div class="icon bg5">
                                        <i class="ri-cloud-line"></i>
                                    </div>
                                    <h3>Cloud Storage</h3>
                                    <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                                </li>
                                <li>
                                    <div class="icon bg3">
                                        <i class="ri-leaf-line"></i>
                                    </div>
                                    <h3>Easy Editable Data</h3>
                                    <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Features Area -->

        <!-- Start Features Area -->
        <div class="features-area pt-100 bg-f9f9f9 pb-75">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title text-uppercase  ">KEY FEATURES</span>
                    <h2>Most Probably Included Best Features Ever</h2>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="features-item">
                            <div class="icon">
                                <i class="ri-eye-line"></i>
                            </div>
                            <h3>High Resolution</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="features-item">
                            <div class="icon bg2">
                                <i class="ri-stack-line"></i>
                            </div>
                            <h3>Retina Ready Screen</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="features-item">
                            <div class="icon bg3">
                                <i class="ri-leaf-line"></i>
                            </div>
                            <h3>Easy Editable Data</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="features-item">
                            <div class="icon bg4">
                                <i class="ri-secure-payment-line"></i>
                            </div>
                            <h3>Fully Secured</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="features-item">
                            <div class="icon bg5">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>Cloud Storage</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="features-item">
                            <div class="icon bg6">
                                <i class="ri-pie-chart-2-line"></i>
                            </div>
                            <h3>Responsive Ready</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Features Area -->

        <!-- Start Features Area -->
        <div class="features-area ptb-100">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title text-uppercase  ">KEY FEATURES</span>
                    <h2>Most Probably Included Best Features Ever</h2>
                </div>
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item without-hover">
                            <div class="icon">
                                <i class="ri-eye-line"></i>
                            </div>
                            <h3>High Resolution</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item without-hover">
                            <div class="icon bg2">
                                <i class="ri-stack-line"></i>
                            </div>
                            <h3>Retina Ready Screen</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item without-hover">
                            <div class="icon bg3">
                                <i class="ri-leaf-line"></i>
                            </div>
                            <h3>Easy Editable Data</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item without-hover">
                            <div class="icon bg4">
                                <i class="ri-secure-payment-line"></i>
                            </div>
                            <h3>Fully Secured</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item without-hover">
                            <div class="icon bg5">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>Cloud Storage</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item without-hover">
                            <div class="icon bg6">
                                <i class="ri-pie-chart-2-line"></i>
                            </div>
                            <h3>Responsive Ready</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
                        <div class="text-center">
                            <a @click="openCalendly" class="default-btn">Schedule Demo</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Features Area -->

        <!-- Start Features Area -->
        <div class="features-area pt-100 bg-f9f9f9 pb-75">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title text-uppercase  ">KEY FEATURES</span>
                    <h2>Most Probably Included Best Features Ever</h2>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="features-item with-border">
                            <div class="icon">
                                <i class="ri-eye-line"></i>
                            </div>
                            <h3>High Resolution</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="features-item with-border">
                            <div class="icon bg2">
                                <i class="ri-stack-line"></i>
                            </div>
                            <h3>Retina Ready Screen</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="features-item with-border">
                            <div class="icon bg3">
                                <i class="ri-leaf-line"></i>
                            </div>
                            <h3>Easy Editable Data</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="features-item with-border">
                            <div class="icon bg4">
                                <i class="ri-secure-payment-line"></i>
                            </div>
                            <h3>Fully Secured</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="features-item with-border">
                            <div class="icon bg5">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>Cloud Storage</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="features-item with-border">
                            <div class="icon bg6">
                                <i class="ri-pie-chart-2-line"></i>
                            </div>
                            <h3>Responsive Ready</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Features Area -->

        <!-- Start Features Area -->
        <div class="features-area pt-100 pb-75">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="features-content">
                            <span class="sub-title text-uppercase  ">KEY FEATURES</span>
                            <h2>Most Probably Included Best Features Ever</h2>
                            <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began.</p>
                            <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want chance its just began.</p>
                            <div class="btn-box">
                                <a @click="openCalendly" class="default-btn">Schedule Demo</a>
                                <a href="#" class="link-btn">See All Features</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="list-of-features">
                            <div class="row justify-content-center">
                                <div class="col-lg-6 col-sm-6 col-md-6">
                                    <div class="features-item with-border">
                                        <div class="icon">
                                            <i class="ri-eye-line"></i>
                                        </div>
                                        <h3>High Resolution</h3>
                                        <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-6 col-md-6">
                                    <div class="features-item with-border">
                                        <div class="icon bg2">
                                            <i class="ri-stack-line"></i>
                                        </div>
                                        <h3>Retina Ready Screen</h3>
                                        <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-6 col-md-6">
                                    <div class="features-item with-border">
                                        <div class="icon bg3">
                                            <i class="ri-leaf-line"></i>
                                        </div>
                                        <h3>Easy Editable Data</h3>
                                        <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-6 col-md-6">
                                    <div class="features-item with-border">
                                        <div class="icon bg4">
                                            <i class="ri-secure-payment-line"></i>
                                        </div>
                                        <h3>Fully Secured</h3>
                                        <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Features Area -->
    </div>
</template>

<script>
export default {
    name: 'Features',
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>