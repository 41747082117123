<template>
    <div class="banner-wrapper-area" v-if="data">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="banner-wrapper-content">
                        <span class="sub-title text-uppercase  ">{{ data?.name || "Meander"}}</span>
                        <h1>{{data?.title}}</h1>
                        <p>{{data?.description}}</p>
                        <button @click="openCalendly" class="default-btn">Schedule Demo</button>
                        <button class="default-btn ms-2" @click="openPopup">Explore Solutions</button>

                        <div>
    
    

  </div>
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="banner-wrapper-image" v-if="data?.screenshot_1?.url || data?.screenshot_2?.url">
                        <img v-if="data?.screenshot_1?.url" :src="$backendUrl+data?.screenshot_1?.url" alt="banner-img" loading="lazy" >
                        <img v-if="data?.screenshot_2?.url" :src="$backendUrl+data?.screenshot_2?.url" data-aos="fade-left" alt="banner-img" loading="lazy" >
                    </div>
                </div>
            </div>
        </div>

        <div class="shape13">
            <img src="../../assets/images/shape/shape15.png" alt="shape">
        </div>
        <div class="shape14">
            <img src="../../assets/images/shape/shape17.png" alt="shape">
        </div>
        <div class="shape15">
            <img src="../../assets/images/shape/shape18.png" alt="shape">
        </div>
    </div>

    
</template>

<style scoped>
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 99999999999999 !important; */
}

.popup {
    background-color: white;
    /* padding: 20px; */
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 700px;
    height: 600px;
}

button {
  margin-top: 10px;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
<script>
export default {
    name: 'MainBanner',
    props: {
  openPopup: Function, // Use the Function type directly
  data: {
      type: [] || {} || null, // Set the prop type to be Any
      required: true,

    },
},
  mounted(){
this.data
},
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }

  }
}
</script>