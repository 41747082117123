<template>
    <div class="blog-area ptb-50">
        <div class="section-title">
                <h2>Latest Articles</h2>
            </div>
       
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6" v-for="(blog,index) in blogs" :key="index">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link :to="`/resource-details/${blog.attributes.slug}`"  class="d-block">
                                        <img style="height:250px;  "  :src="baseUrl + blog.attributes.image.data.attributes.url" alt="blog">
                                        <!-- <img  :src="blog.blog_img.url" alt="blog"> -->
                                    </router-link>
                            <!-- <router-link to="/blog-grid" class="tag">Branding</router-link> -->
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <!-- <li><i class="ri-time-line"></i> April 14, 2021</li> -->
                                <!-- <li><i class="ri-message-2-line"></i> <router-link to="/">(0) Comment</router-link></li> -->
                            </ul>
                            <h3><router-link :to="`/resource-details/${blog.attributes.slug}`">{{ blog.attributes.title }}</router-link></h3>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/" class="d-block">
                                <img src="../../assets/images/blog/blog2.jpg" alt="blog">
                            </router-link>
                            <router-link to="/blog-grid" class="tag">Agency</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> April 13, 2021</li>
                                <li><i class="ri-message-2-line"></i> <router-link to="/">(4) Comment</router-link></li>
                            </ul>
                            <h3><router-link to="/">Design is a plan or specification for the construction of an object</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/" class="d-block">
                                <img src="../../assets/images/blog/blog3.jpg" alt="blog">
                            </router-link>
                            <router-link to="/blog-grid" class="tag">Marketing</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> April 12, 2021</li>
                                <li><i class="ri-message-2-line"></i> <router-link to="/">(2) Comment</router-link></li>
                            </ul>
                            <h3><router-link to="/">The new minimum is a digital magazine with a header featuring a thin</router-link></h3>
                        </div>
                    </div>
                </div> -->
              <!--    <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/" class="d-block">
                                <img src="../../assets/images/blog/blog4.jpg" alt="blog">
                            </router-link>
                            <router-link to="/blog-grid" class="tag">Branding</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> April 14, 2021</li>
                                <li><i class="ri-message-2-line"></i> <router-link to="/">(0) Comment</router-link></li>
                            </ul>
                            <h3><router-link to="/">WordPress is open source software you can use to create a beautiful</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/" class="d-block">
                                <img src="../../assets/images/blog/blog5.jpg" alt="blog">
                            </router-link>
                            <router-link to="/blog-grid" class="tag">Agency</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> April 13, 2021</li>
                                <li><i class="ri-message-2-line"></i> <router-link to="/">(4) Comment</router-link></li>
                            </ul>
                            <h3><router-link to="/">Bootstrap 5 is open source software you can use to create a beautiful</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/" class="d-block">
                                <img src="../../assets/images/blog/blog6.jpg" alt="blog">
                            </router-link>
                            <router-link to="/blog-grid" class="tag">Marketing</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> April 12, 2021</li>
                                <li><i class="ri-message-2-line"></i> <router-link to="/">(2) Comment</router-link></li>
                            </ul>
                            <h3><router-link to="/">Beautiful designs, powerful features, and the freedom to build anything you</router-link></h3>
                        </div>
                    </div>
                </div>
               <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/" class="d-block">
                                <img src="../../assets/images/blog/blog7.jpg" alt="blog">
                            </router-link>
                            <router-link to="/blog-grid" class="tag">Branding</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> April 14, 2021</li>
                                <li><i class="ri-message-2-line"></i> <router-link to="/">(0) Comment</router-link></li>
                            </ul>
                            <h3><router-link to="/">Branding involves developing strategy to create a point of differentiation</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/" class="d-block">
                                <img src="../../assets/images/blog/blog8.jpg" alt="blog">
                            </router-link>
                            <router-link to="/blog-grid" class="tag">Agency</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> April 13, 2021</li>
                                <li><i class="ri-message-2-line"></i> <router-link to="/">(4) Comment</router-link></li>
                            </ul>
                            <h3><router-link to="/">Most designs, powerful features, and the freedom to build anything you</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/" class="d-block">
                                <img src="../../assets/images/blog/blog9.jpg" alt="blog">
                            </router-link>
                            <router-link to="/blog-grid" class="tag">Marketing</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> April 12, 2021</li>
                                <li><i class="ri-message-2-line"></i> <router-link to="/">(2) Comment</router-link></li>
                            </ul>
                            <h3><router-link to="/">Branding involves developing strategy to create a point</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">
                        <div class="nav-links">
                            <span class="page-numbers current">1</span>
                            <router-link to="/blog-grid" class="page-numbers">2</router-link>
                            <router-link to="/blog-grid" class="page-numbers">3</router-link>
                            <router-link to="/blog-grid" class="next page-numbers" title="Next Page"><i class="ri-arrow-right-line"></i></router-link>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Blog',
    data(){
      return{
        isLoading: true,
            blogs: [],
            baseUrl: 'https://content.meander.software',
            currentPage: 1,
            itemsPerPage: 12,
            totalItems: 0,
            totalPages: 0,
      }
    },
    methods:{
        async getBlogs() {
    
    try {
        const response = await axios.get(`${this.$baseUrl}/blogs?pagination[withCount]=true&pagination[page]=${this.currentPage}&populate=*`);
        // this.blogs = response.data;
        // Destructure the 'data' and 'total' properties from the response

        const { data } = response;
       
        const reversedData = data;
        this.blogs = reversedData?.data?.reverse().slice(0,3);
 

   
    } catch (error) {
        console.error(error);
    }
},
    },
    mounted: async function (){
       await this.getBlogs()
    }
}
</script>