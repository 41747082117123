<template>
    <div class="features-area pt-100 pb-75">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-inner-content">
                        <span class="sub-title text-uppercase  ">KEY FEATURES</span>
                        <h2>Most Probably Included Best Features Ever</h2>
                        <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began.</p>
                        <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want chance its just began.</p>
                        <div class="btn-box">
                            <router-link to="/pricing" class="default-btn">Schedule Demo</router-link>
                            <router-link to="/features-two" class="link-btn">See All Features</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 features-inner-list">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <div class="features-inner-card">
                                <div class="icon">
                                    <i class="ri-eye-line"></i>

                                    <h3>High Resolution</h3>
                                </div>
                                <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="features-inner-card with-box-shadow">
                                <div class="icon">
                                    <i class="ri-stack-line"></i>
                                    
                                    <h3>Retina Ready Screen</h3>
                                </div>
                                <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="features-inner-card with-box-shadow">
                                <div class="icon">
                                    <i class="ri-cloud-line"></i>
                                    
                                    <h3>Cloud Storage</h3>
                                </div>
                                <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="features-inner-card">
                                <div class="icon">
                                    <i class="ri-leaf-line"></i>
                                    
                                    <h3>Easy Editable Data</h3>
                                </div>
                                <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IncludedBestFeatures',
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>