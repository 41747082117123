<template>
    <div class="team-area pt-100 pb-75" >
        <div class="container">
            <div class="section-title" >
                <!-- <span class="sub-title text-uppercase  ">{{fieldname}}</span> -->
                <h2>Services We Are Offer</h2>
            </div>
              <div class="row justify-content-center">
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6" v-for="offers in data[0]?.attributes?.ms_industries_key_services_industries?.data" :key="offers?.id">
                        <div class="single-team-box">
                            <div class="image">
                                <div
                                 ><img :src='`https://content.meander.software/${offers?.attributes?.image?.data?.attributes?.url}`' alt='image'/></div>
                            </div>
                            <div class="content">
                                <h3>{{offers?.attributes?.name}}</h3><span>{{offers?.attributes?.role}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div class="single-team-box">
                            <div class="image">
                                <div class="bg-2"></div>
                            </div>
                            <div class="content">
                                <h3>Ben Stokes</h3><span>Art Director</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div class="single-team-box">
                            <div class="image">
                                <div class="bg-3"></div>
                            </div>
                            <div class="content">
                                <h3>Ellyse Perry</h3><span>Designer</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div class="single-team-box">
                            <div class="image">
                                <div class="bg-4"></div>
                            </div>
                            <div class="content">
                                <h3>Steven Smith</h3><span>Development</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div class="single-team-box">
                            <div class="image">
                                <div class="bg-5"></div>
                            </div>
                        <div class="content">
                            <h3>Suzie Bates</h3><span>Front-End Development</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-6"></div>
                        </div>
                        <div class="content">
                            <h3>David Warner</h3><span>Head of Team</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-7"></div>
                        </div>
                        <div class="content">
                            <h3>Ross Taylor</h3><span>Front-End Developer</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-8"></div>
                        </div>
                        <div class="content">
                            <h3>Meg Lanning</h3><span>Art Director</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-9"></div>
                        </div>
                        <div class="content">
                            <h3>Du Plessis</h3><span>Designer</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-10"></div>
                        </div>
                        <div class="content">
                            <h3>Ellyse Perry</h3><span>Development</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-11"></div>
                        </div>
                        <div class="content">
                            <h3>Kagiso Steyn</h3><span>Front-End Development</span>
                        </div>
                    </div>
                </div> -->
            </div>

         
    </div>
</div></template>

<script>
export default {
    name: 'IndustriesService',
    props:{
     data:[],
    },
   
}
</script>