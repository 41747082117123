<template>
    <Preloader v-if="isLoading" />
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <span class="sub-title text-uppercase  ">{{aboutUs?.attributes?.title}}</span>
                        <h2>Most Probably You Are Getting Best App Ever</h2>
                        <p>{{ aboutUs?.attributes?.description }}</p>
                        <div class="features-text">
                            <h6>Our App</h6>
                            <p>{{aboutUs?.attributes?.our_app}}</p>
                        </div>
                        <div class="features-text">
                            <h6>Our Mission</h6>
                            <p>{{aboutUs?.attributes?.our_mission}}</p>
                        </div>
                        <div class="btn-box">

                            <button @click="openCalendly" class="default-btn">Schedule Demo</button>
                            <!-- <router-link to="/features-one" class="link-btn">See All Features</router-link> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-img">
                        <img src="../../assets/images/app/app-img8.png" data-aos="fade-up" alt="about">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Preloader from '../Layout/Preloader.vue'
export default {
    name: 'About',
    components: {
        Preloader
    },       
    data() {
        return {
            isLoading: true,
            baseUrl: 'https://content.meander.software',
            aboutUs: Object
        }
    },
    methods: {
        openCalendly() {
           // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
        },
        async getAboutUs() {
            try {
                // Fetch abou us data from Strapi API
                const response = await axios.get(`${this.$baseUrl}/ms-about-uses`);
                const { data      } = response;
                this.aboutUs = data['data'][0];
            } catch (error) {
                this.aboutUs=new Object();
            }
        },

    },
    mounted: async function () {
        await this.getAboutUs();
        this.isLoading = false;
    }
}
</script>