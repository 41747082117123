<template>
    <div class="features-area pb-75 pt-75">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                    <div class="features-box-card">
                        <div class="icon">
                            <i class="ri-smartphone-line"></i>
                        </div>
                        <h3>User Friendly</h3>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                    <div class="features-box-card">
                        <div class="icon bg2">
                            <i class="ri-award-line"></i>
                        </div>
                        <h3>Award Winning App</h3>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                    <div class="features-box-card">
                        <div class="icon">
                            <i class="ri-fingerprint-line"></i>
                        </div>
                        <h3>Privacy Protected</h3>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                    <div class="features-box-card">
                        <div class="icon bg2">
                            <i class="ri-vip-diamond-line"></i>
                        </div>
                        <h3>Lifetime Update</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>