<template>
    <div>
       
       
        <!-- Start Features Area -->
        <div class="features-area ptb-100 bg-F7F7FF">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title text-uppercase  ">KEY FEATURES</span>
                    <h2>Most Probably Included Best Features Ever</h2>
                </div>
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon">
                                <i class="ri-eye-line"></i>
                            </div>
                            <h3>Extensive API Marketplace</h3>
                            <p>Access a vast library of APIs across various categories, empowering you to integrate diverse functionalities into your applications effortlessly.    </p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg2">
                                <i class="ri-stack-line"></i>
                            </div>
                            <h3>Stream Integration Process</h3>
                            <p> Simplify the integration of APIs with Meander One's intuitive interface, standardized endpoints, and unified authentication methods.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg3">
                                <i class="ri-leaf-line"></i>
                            </div>
                            <h3>Developer-Friendly Tools</h3>
                            <p>Boost your productivity with code snippets, SDKs, and comprehensive documentation, enabling you to implement APIs quickly and efficiently.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg4">
                                <i class="ri-secure-payment-line"></i>
                            </div>
                            <h3>Real-time Analytics</h3>
                            <p> Gain valuable insights into API performance, usage, and errors, allowing you to optimize your integration and make data-driven decisions.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg5">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>
                                Collaborative Workspaces</h3>
                            <p>Collaborate seamlessly with team members, share knowledge, and work together on API integration projects in dedicated collaborative workspaces.
</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg6">
                                <i class="ri-pie-chart-2-line"></i>
                            </div>
                            <h3>
                                Reliable Security  </h3>
                            <p>Rest assured knowing that your data and integrations are protected with robust security measures and adherence to industry standards  </p>
                        </div>
                    </div>
                    <!-- <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
                        <div class="view-more-box">
                            <a href="#" class="default-btn">View More</a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- End Features Area -->

    </div>
</template>

<script>
export default {
    name: 'KeyFeatures',
}
</script>