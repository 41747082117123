<template>
    <div class="whatsapp-button">
      <a
        :href="whatsappLink"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img class="whatsappLogo" src="../../assets/images/whatsapplogo.png" alt="WhatsApp" />
      </a>
    </div>
  </template>
  
  <script>
  export default {
    name:'WhatsApp',
    data() {
      return {
        // Replace 'YOUR_PHONE_NUMBER' with your actual WhatsApp phone number
        phoneNumber: '+918090549054',
      };
    },
    computed: {
      whatsappLink() {
        return `https://wa.me/${this.phoneNumber}`;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add your button styling here */
  .whatsapp-button {
    position: fixed;
    bottom: 88px;
    left: 20px;
   z-index: 999 !important;

  }
  .whatsappLogo {
   width: 65px;
  }
  </style>
  