<template>
     <FormModal v-if="showPopup" :closePopup="closePopup" />

<div >
  <Navbar :openPopup="openPopup" />
        <!-- <PageTitle pageTitle="Doers Online" /> -->
        <MainBanner />
        <KeyFeatures/>
        <!-- <CustomFeatures /> -->
        <Team />
        <Features/>
        <WhatDoWeOffer/>
        <!-- <MeanderFeatures /> -->
        <!-- <BestFeaturesEver /> -->
        <TeamTwo />
        <!-- <Video /> -->
<AppDownloadOne />
<Faq />
<!-- <FeedbackTwo /> -->

<Client/>    
<iframe frameborder="0" style="height:500px;width:99%;border:none;" src='https://forms.zohopublic.in/meandersoftwarecontact/form/ClientNeedsAssessmentForm/formperma/4fjH7ZBQUI7rdg6ZqA85n4ldQD4ajNkC_-RXMdrXXPs'></iframe>

        <FooterStyleFour />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'

 import MainBanner from "./MainBanner.vue"

import Team from "./Team.vue";

  import Faq from "../Faq/Faq"
import TeamTwo from '../TeamTwo/Team.vue'

 import FooterStyleFour from '../Layout/FooterStyleFour'
import KeyFeatures from './KeyFeatures.vue';
import Features from './Features.vue';
import WhatDoWeOffer from './WhatDoWeOffer.vue';
import FormModal from '../HomeThree/FormModal.vue';
import AppDownloadOne from './AppDownloadOne.vue';
import Client from '../DoersOnline/Client.vue';





export default {
    name: 'DrawtopicDigital',
    components: {
    Navbar,
    MainBanner,
    Team,
    Faq,
    TeamTwo,
    AppDownloadOne,
    FooterStyleFour,
    KeyFeatures,
    Features,
    WhatDoWeOffer,
    FormModal,
    Client
},
data(){
    return{
        showPopup:false
    }
},
methods:{
  openPopup(){
     this.showPopup  = true
    },
    closePopup(){
     this.showPopup= false
    },
}
}
</script>