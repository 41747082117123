<template>
    <div class="app-about-area pb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="app-about-image">
                        <img src="../../assets/images/more-home/about/about.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="app-about-content">
                        <div class="big-text">About Us</div>
                        <span class="sub-title text-uppercase  ">ABOUT US</span>
                        <h2>Most Probably You Are Getting Best App Ever</h2>
                        <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began.</p>
                        <ul class="list">
                            <li>
                                <div class="icon">
                                    <i class="ri-award-line"></i>
                                </div>
                                <h3>Trusted and Reliable</h3>
                                <p>Most provabily best you can trust on it, just log in with your mail account from play store and using whatever you want for your business.</p>
                            </li>
                            <li>
                                <div class="icon bg2">
                                    <i class="ri-download-cloud-2-line"></i>
                                </div>
                                <h3>Cloud Storage</h3>
                                <p>Just log in with your mail account from play store and using whatever you want for your business purpose.</p>
                            </li>
                        </ul>
                        <div class="btn-box">
                             <button @click="openCalendly" class="default-btn">Schedule Demo</button>
                            <router-link to="/features-one" class="link-btn">See All Features</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="app-about-shape-1">
            <img src="../../assets/images/more-home/about/shape-1.png" alt="image">
        </div>
        <div class="app-about-shape-2">
            <img src="../../assets/images/more-home/about/shape-2.png" alt="image">
        </div>
        <div class="app-about-shape-3">
            <img src="../../assets/images/more-home/about/shape-3.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
    ,
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>