<template>
    <div>
        <CustomerReviews class="bg-F4F8FC" />
    </div>
</template>

<script>
import CustomerReviews from '../Common/CustomerReviews'

export default {
    name: 'Feedback',
    components: {
        CustomerReviews
    }
}
</script>