<template>
    <div class="features-area pt-100 pb-75">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                  <a
        :href="whatsappLink"
        target="_blank"
        rel="noopener noreferrer"
      ><div class="features-card">
                        <div class="icon">
                            <i class="ri-whatsapp-line"></i>

                            <div class="number">1</div>
                        </div>
                        <h3>Whatsapp</h3>
                        <p>         (+91) 80-9054-9054</p>
                    </div></a>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">


                  
                  <a href="mailto:hi@meander.software"><div class="features-card">
                        <div class="icon">
                            <i class="ri-mail-send-line"></i>

                            <div class="number">2</div>
                        </div>
                        <h3>Email</h3>
                        <p>hi@meander.software</p>
                    </div></a>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">

                  <router-link to="/contact" class="nav-link"> 
                    <div class="features-card">
                        <div class="icon">
                            <i class="ri-smartphone-line"></i>

                            <div class="number">3</div>
                        </div>
                        <h3>Contact us</h3>
                        <p>(+91) 80-9054-9054</p>
                    </div> </router-link>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                    <div class="features-card">
                        <div class="icon">
                            <i class="ri-apps-fill"></i>

                            <div class="number">4</div>
                        </div>
                        <h3>Follow Us</h3>
                        <ul class="social-links" style="display: flex ; margin-bottom: 0px; flex-wrap:  wrap;">
              <li style="list-style: none; margin:0px 10px; color: #fff;">
                <a
                  href="https://www.facebook.com/meandersoftware?mibextid=ZbWKwL"
                  target="_blank"
                  ><i  style="color: #fff;" class="ri-facebook-fill"></i></a>
              </li>
              <li style="list-style: none; margin:0px 10px; color: #fff;">
                <a href="https://x.com/meandersoftware?" target="_blank"
                  ><i style="color: #fff;"  class="ri-twitter-fill"></i
                ></a>
              </li>
              <li style="list-style: none; margin:0px 10px; color: #fff;">
                <a
                  href="https://www.linkedin.com/company/meandersoftware/"
                  target="_blank"
                  ><i style="color: #fff;"  class="ri-linkedin-fill"></i
                ></a>
              </li>
              <li style="list-style: none; margin:0px 10px; color: #fff;">
                <a
                  href="https://www.instagram.com/meander.software/"
                  target="_blank"
                  ><i style="color: #fff;"  class="ri-instagram-fill"></i
                ></a>
              </li>
              <li style="list-style: none; margin:0px 10px; color: #fff;">
                <a
                  href="https://www.youtube.com/@meandersoftware"
                  target="_blank"
                  ><i style="color: #fff;"  class="ri-youtube-fill"></i
                ></a>
              </li>
              <!-- <li>
                                <a href="https://github.com/" target="_blank"><i class="ri-github-fill"></i></a>
                            </li> -->
            </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features',
    data() {
      return {
        // Replace 'YOUR_PHONE_NUMBER' with your actual WhatsApp phone number
        phoneNumber: '+918090549054',
      };
    },
    computed: {
      whatsappLink() {
        return `https://wa.me/${this.phoneNumber}`;
      },
    },
  }
</script>