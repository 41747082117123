<template>
    <div class="features-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <!-- <span class="sub-title text-uppercase  ">Recognisation  & Certifications</span> -->
                <h2>Recognisation  & Certifications</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="ri-open-arm-fill"></i>
                        </div>
                        <h6>YOUNGEST ENTREPRENEUR STPI - 2012</h6>
                        <p class="fw-bold">Pioneering Excellence at a Young Age</p>
                        <p>Awarded the title of "Youngest Entrepreneur STPI - 2012," recognizing its achievements as a young Software company</p>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon bg2">
                            <i class="ri-file-list-3-line"></i>
                        </div>
                        <h6> ISO 20000-1:2018 Certified</h6>
                        <p class="fw-bold">Committed to International Excellence</p>
                        <p>We proudly hold ISO 20000-1:2018 certification for compliance with international standards for information technology service management systems (ITSM)
</p>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon bg3">
                            <i class="ri-scissors-cut-line"></i>
                        </div>
                        <h6> NASSCOM Best Shortlisted Product - 2019</h6>
                        <p class="fw-bold">Innovation Recognized on a National Stage</p>
                        <p>Meander Software's product was shortlisted for the prestigious NASSCOM Best Product Award 2019
</p>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon bg4">
                            <i class="ri-parent-fill"></i>
                        </div>
                        <h6>Emerging Technology Partners - 2021</h6>
                        <p class="fw-bold">Leading the Charge in Innovation</p>
                        <p>Recognition as an emerging technology partner in 2021, highlighting its expertise and innovation in delivering cutting-edge IT solutions to clients
</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>