<template>
    <GallerySkelton v-if="isLoading"/>

    <div v-else class="gallery-area pt-100 ">
        <div class="container">
            <lightgallery
                :settings="{ speed: 500, plugins: plugins }"
                :onInit="onInit"
                :onBeforeSlide="onBeforeSlide"
                class="row justify-content-center"
            >
              <a
                    v-for="item in data"
                    :key="item.id"
                    :data-lg-size="item?.attributes?.image?.data?.attributes?.size"
                    className="gallery-item"
                    :data-src="`https://content.meander.software/${item?.attributes?.image?.data?.attributes?.url}`"
                    class="col-lg-4 col-md-6 col-sm-6 "
                >
                <router-link :to="`/success-detail/${item?.attributes?.slug}`">
                    <div class="single-gallery-item">
                        <img style="    cursor: -webkit-grab;
cursor: grab;" className="img-responsive cursor-pointer" :src="`https://content.meander.software/${item?.attributes?.image?.data?.attributes?.url}`" />
                    </div>
               </router-link>  </a> 
            </lightgallery>
           
        </div>
    </div>
</template>

<script>
import Lightgallery from 'lightgallery/vue';
import lgZoom from 'lightgallery/plugins/zoom';
import GallerySkelton from './GallerySkelton.vue'
let lightGallery= null;

export default {
    name: 'GalleryThree',
    props:{
     data:[]
    },
    components: {
        Lightgallery,
        GallerySkelton
    },
    watch: {
        items() {
            this.$nextTick(() => {
                lightGallery.refresh();
            });
        },
    },
    data: () => ({
        isLoading: true,
        plugins: [lgZoom],
        items: [
            {
                id: '1',
                src: require('../../../assets/images/gallery/gallery1.jpg'),
                thumb: require('../../../assets/images/gallery/gallery1.jpg'),
            },
            {
                id: '2',
                src: require('../../../assets/images/gallery/gallery2.jpg'),
                thumb: require('../../../assets/images/gallery/gallery2.jpg'),
            },
            {
                id: '3',
                src: require('../../../assets/images/gallery/gallery3.jpg'),
                thumb: require('../../../assets/images/gallery/gallery3.jpg'),
            },
            {
                id: '4',
                src: require('../../../assets/images/gallery/gallery4.jpg'),
                thumb: require('../../../assets/images/gallery/gallery4.jpg'),
            },
            {
                id: '5',
                src: require('../../../assets/images/gallery/gallery5.jpg'),
                thumb: require('../../../assets/images/gallery/gallery5.jpg'),
            },
            {
                id: '6',
                src: require('../../../assets/images/gallery/gallery6.jpg'),
                thumb: require('../../../assets/images/gallery/gallery6.jpg'),
            },
        
        ],
   
    }),
  

  methods: {
        onInit: (detail) => {

            lightGallery = detail.instance;
        },
        onBeforeSlide: () => {
        
        },

    
    },
    mounted() {
    // Simulate loading your data from an API
    setTimeout(() => {
      // Set isLoading to false when your data is ready
      this.isLoading = false;
    }, 2000); // Adjust the timeout as needed
  },
   
}
</script>

<style lang="css">
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.1.0-beta.1/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.1.0-beta.1/css/lg-zoom.css');
</style>