<template>
    <div class="new-feedback-wrap-area pb-100">
        <div class="container-fluid">
            <div class="section-title">
                <!-- <span class="sub-title text-uppercase  ">CLIENT REVIEWS</span> -->
                <h2>Industries We Serve</h2>
            </div>
            <div class="new-feedback-wrap-slides">
                <carousel
                    :autoplay="5000"
                    :settings='settings'
                    :breakpoints='breakpoints'
                    :wrap-around="true"
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="single-feedback-wrap-card">
                          
                            <div class="client-info">
                                <img :src="slide.image" alt="user">
                               
                                <div class="title">
                                    <h3>{{slide.name}}</h3>
                                    <span>{{slide.position}}</span>
                                </div>
                            </div>
                            <p>{{slide.description}}</p>

                            <!-- <div class="rating">
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                            </div> -->
                        </div>
                    </slide>

                    <template #addons>
                        <navigation>
                            <template #next>
                                <i class='ri-arrow-right-s-line'></i>
                            </template>
                            <template #prev>
                                <i class='ri-arrow-left-s-line'></i>
                            </template>
                        </navigation>
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'CustomerReviews',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        // carouselItems: [
        //     {
        //         id: 1,
        //         image: require('../../assets/images/user/user1.jpg'),
        //         name: 'Deanna Hodges',
        //         position: 'Spotify Developer',
        //         description: '"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type."',
        //     },
        //     {
        //         id: 2,
        //         image: require('../../assets/images/user/user2.jpg'),
        //         name: 'Londynn Vargas',
        //         position: 'PHP Developer',
        //         description: '"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type."',
        //     },
        //     {
        //         id: 3,
        //         image: require('../../assets/images/user/user3.jpg'),
        //         name: 'James Andy',
        //         position: 'Moodle Developer',
        //         description: '"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type."',
        //     },
        //     {
        //         id: 4,
        //         image: require('../../assets/images/user/user4.jpg'),
        //         name: 'David Warner',
        //         position: 'Client since 2015',
        //         description: '"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type."',
        //     },
        // ],
        carouselItems: [
                {
                    id: 1,
                    description: 'Meander Software provides tailored software solutions for education and training providers. Our solutions include learning management systems, virtual classrooms, and online assessment tools, enabling institutions to deliver high-quality content and training to their students seamlessly.',
                    name: 'Education & Training',
                    position: '',
                    image: require('../../assets/images/user/11.png')
                },
                {
                    id: 2,
                    description: ' Meander Software offers personalized ecommerce solutions that help businesses enhance their online presence and expand their audience reach. Our solutions include online marketplaces, mobile apps, and payment gateway integrations, allowing businesses to sell their products and services online seamlessly.',
                    name: 'Retail & Ecommerce',
                    position: '',
                    image: require('../../assets/images/user/12.png')
                },
                {
                    id: 3,
                    description: 'Meander Software provides innovative healthcare solutions to help providers deliver better patient care and streamline operations. Our solutions include electronic health records, telemedicine platforms, and patient engagement tools, empowering providers to deliver effective and efficient care to their patients.',
                    name: 'Health & wellness',
                    position: '',
                    image: require('../../assets/images/user/13.png')
                },
                {
                    id: 4,
                    description: 'Meander Software offers tailored CRM solutions to help businesses manage customer relationships more effectively. Our solutions include customer database management, sales automation tools, and customer analytics, enabling businesses to better understand their customers needs and preferences.',
                    name: 'CRM',
                    position: '',
                    image: require('../../assets/images/user/14.png')
                },
                {
                    id: 5,
                    description: ' Meander Software offers logistics solutions that help businesses to streamline their operations and improve their supply chain management. Our solutions include transportation management systems, warehouse management systems, and inventory management tools that enable businesses to reduce costs and improve efficiency.',
                    name: 'Logistics',
                    position: '',
                    image: require('../../assets/images/user/15.png')
                },
                {
                    id: 6,
                    description: 'Meander Software provides fintech solutions that help financial institutions to improve their operations and enhance their customer experience. Our solutions include mobile banking apps, payment processing systems, and financial analytics tools that enable institutions to provide better services to their customers.',
                    name: 'Fintech',
                    position: '',
                    image: require('../../assets/images/user/16.png')
                },
                {
                    id: 7,
                    description: 'Meander Software offers social networking solutions that help businesses to engage with their customers and build communities around their brands. Our solutions include social media management tools, community forums, and user-generated content platforms that enable businesses to foster greater customer engagement and loyalty.',
                    name: 'Social Networking',
                    position: '',
                    image: require('../../assets/images/user/17.png')
                },
            ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
        },
    }),
})
</script>