<template>
    <div class="app-progress-area bg-black ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="app-progress-animation-image" :style="{ paddingBottom: '0px' }"> <!--dd-->
                        <img src="../../assets/images/app-progress/section10.png" alt="app-progress" data-aos="fade-down" >
                        <!-- <img src="../../assets/images/app-progress/app-progress1.png" alt="app-progress" data-aos="fade-down" data-aos-duration="1000">
                        <img src="../../assets/images/app-progress/app-progress2.png" alt="app-progress" data-aos="fade-right" data-aos-duration="1000">
                        <img src="../../assets/images/app-progress/app-progress3.png" alt="app-progress" data-aos="fade-left" data-aos-duration="1000">
                        <img src="../../assets/images/app-progress/app-progress4.png" alt="app-progress" data-aos="fade-up" data-aos-duration="1000">
                        <img src="../../assets/images/app-progress/progress-main.png" class="main-image" alt="app-progress"> -->
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="app-progress-content text-white">
                        <span class="sub-title text-uppercase  ">#Technology Excellence</span>
                        <h2> Stay Ahead with Modern App Development</h2>
                        <p>Meander Software specializes in cutting-edge technologies like payment gateways for secure transactions and AI for intelligent systems. Our expertise extends to IoT, facilitating seamless device connectivity and transforming industries. With machine learning, we craft predictive models and personalized experiences, especially in healthcare and fraud detection.</p>
                        <router-link to="/contact" class="nav-link">  <button   class="default-btn">Contact Us</button>   </router-link>
   
                        <!-- <a href="#" class="default-btn">Schedule Demo</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChallengesAndTrack',
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>