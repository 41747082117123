<template>
    <div class="new-app-progress-area ptb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="new-app-progress-content">
                        <div class="big-text"></div>
                        <span class="sub-title text-uppercase  ">Empower Growth</span>
                        <h2>Why Meander?</h2>
                        <p>At Meander Software, we stand out as a top choice for software development. Our team of experienced developers excels in swift project delivery, ensuring you reach the market faster while maintaining competitive pricing.<br> Quality assurance is at the core of our work, guaranteeing reliable and secure software. We provide customized solutions, tailoring our approach to your unique needs. Our client-centric approach ensures your success is our priority, with collaboration, transparency, and support throughout the project. Choose Meander Software to experience exceptional results and join our family of innovative professionals shaping the future of technology.</p>
                        <br>
                        <router-link to="/contact" class="nav-link"><button class="default-btn">Get Started</button></router-link>
                       
                    </div>

                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="new-app-progress-image text-center">
                        <img src="../../assets/images/more-home/progress/Why-Meander.png" alt="app-img">
                    </div>
                </div>
            </div>
        </div>
        <div class="new-app-progress-shape">
            <img src="../../assets/images/more-home/progress/shape-1.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChallengesAndTrack',
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>