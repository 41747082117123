<template>
    <div class="team-area pt-100 pb-75" >
        <div class="container">
              <div class="row justify-content-center">
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div class="single-team-box">
                            <div class="image">
                                <div class="bg-9">

                                    <img src="../../assets/images/meanderoneservices/AnalyticsandDataAnalysis.png" alt="image">
                                </div>
                        </div>
                            <div class="content">
                                <h3> Data Analysis</h3>
                                <!-- <span>Marketing Lead</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div class="single-team-box">
                            <div class="image">
                                
                                <div class="bg-9">

                                    <img src="../../assets/images/meanderoneservices/ConversionRateOptimization.png" alt="image">
                                </div>
                        </div>
                            <div class="content">
                                <h3>CRO</h3>
                                <!-- <span>Art Director</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div class="single-team-box">
                            <div class="image">
                                <div class="bg-9">

                                    <img src="../../assets/images/meanderoneservices/EmailMarketing.png" alt="image">
                                </div>
                        </div>
                            <div class="content">
                                <h3>Email Marketing</h3>
                                <!-- <span>Designer</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div class="single-team-box">
                            <div class="image">
                                <div class="bg-9">

                                    <img src="../../assets/images/meanderoneservices/InfluencerMarketing.png" alt="image">
                                </div>
                        </div>
                            <div class="content">
                                <h3>Influencer Marketing</h3>
                                <!-- <span>Development</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div class="single-team-box">
                            <div class="image">
                                <div class="bg-9">

                                    <img src="../../assets/images/meanderoneservices/LocalLocalSEO.png" alt="image">
                                </div>
                        </div>
                        <div class="content">
                            <h3>Local SEO</h3>
                            <!-- <span>Front-End Development</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-9">

                                <img src="../../assets/images/meanderoneservices/MarketingAutomation.png" alt="image">
                            </div>
                        </div>
                        <div class="content">
                            <h3>Marketing Automation</h3>
                            <!-- <span>Head of Team</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-9">

                                <img src="../../assets/images/meanderoneservices/MarketingStrategyandPlanning.png" alt="image">
                            </div>
                        </div>
                        <div class="content">
                            <h3>Marketing Planning</h3>
                            <!-- <span>Front-End Developer</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-9">

                                <img src="../../assets/images/meanderoneservices/Pay-Per-ClickAdvertising.png" alt="image">
                            </div>
                        </div>
                        <div class="content">
                            <h3>PPC</h3>
                            <!-- <span>Art Director</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-9">
                                <img src="../../assets/images/meanderoneservices/seo.png" alt="image">
                            </div>
                        </div>
                        <div class="content">
                            <h3>SEO</h3>
                            <!-- <span>Development</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-9">
                                <img src="../../assets/images/meanderoneservices/SocialMediaMarketing.png" alt="image">
                            </div>
                        </div>
                        <div class="content">
                            <h3>Social Media Marketing</h3>
                            <!-- <span>Development</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-9">
                                <img src="../../assets/images/meanderoneservices/VideoMarketing.png" alt="image">
                            </div>
                        </div>
                        <div class="content">
                            <h3>Video Marketing</h3>
                            <!-- <span>Development</span> -->
                        </div>
                    </div>
                </div>
            </div>

         
    </div>
</div></template>

<script>
export default {
    name: 'Technology',
   
}
</script>