<template>
    <div class="main-banner">
        <div class="container">
            <div class="content">
                <span class="sub-title text-uppercase  ">Get your 14 days free trail</span>
                <h1>The Revolutionary App That Makes Your Life Easier Than Others</h1>
                <router-link to="/sign-in" class="default-btn">Schedule Demo</router-link>
                <img src="../../assets/images/banner/banner-app3.png" alt="image">
            </div>
        </div>
        <div class="divider"></div>
        <div class="banner-shape3">
            <img src="../../assets/images/shape/shape2.png" alt="image">
        </div>
        <div class="banner-shape8">
            <img src="../../assets/images/shape/shape11.png" alt="image">
        </div>
        <div class="banner-shape7">
            <img src="../../assets/images/shape/shape11.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>