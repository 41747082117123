<template>
    <div class="team-area pt-100 pb-75">
        <div class="container">
            <div class="section-title" >
                <!-- <span class="sub-title text-uppercase  ">{{fieldname}}</span> -->
                <h2>Technologies We Are Expert In</h2>
            </div>
            <div class="row justify-content-center">


                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4"
                    v-for="technlology in our_expertises" :key="technlology?.name">
                    <div class="single-team-box">
                        <!-- <div class="image"> -->
                        <div><img :src="$backendUrl + technlology?.logo?.url" loading="lazy"></div>
                        <!-- </div> -->
                        <div class="content">
                            <!-- <h3>{{ technlology?.attributes?.name }}</h3> -->
                            <!-- <span>Client since 2018</span> -->
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Team',
    props: {
        our_expertises: {
            type: [], // Set the prop type to be Any
            required: true,
        },
    }
 
}
</script>