<template>
    <div class="features-area pt-100 pb-75" >
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-content">
                        <span class="sub-title text-uppercase  ">User-Friendly CRM

</span>
                        <h2>The Easiest CRM Solution for Streamlined Operations
</h2>
                        <p>Experience the ease and simplicity of Doers CRM, designed to provide you with a user-friendly CRM experience. Our intuitive interface, combined with powerful features, ensures that you can navigate and leverage the system effortlessly. Say goodbye to complex and cumbersome CRM solutions – with Doers CRM, you'll enjoy a seamless and hassle-free experience.
                              </p>
                        <div class="btn-box">
                            <a @click="openCalendly" class="default-btn">Schedule Demo</a>
                            <!-- <a href="#" class="link-btn">See All Features</a> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12" >
                    <div class="list-of-features">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>Intuitive Interface</h3>
                                    <p> User-friendly design for easy navigation, no extensive training required.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>Quick Setup</h3>
                                    <p>Swift onboarding with comprehensive materials for a seamless start to customer relationship management.
</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>Simple Customization</h3>
                                    <p>Easily adapt Doers CRM to your unique business processes and requirements.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>Seamless Integration</h3>
                                    <p>Connect with existing tools, centralize data, and streamline workflows for improved efficiency.</p>
                                </div>
                            </div>
                          
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhatDoWeOffer',
    props: {
        data: {
      type: [], // Set the prop type to be Any
      required: true,
    },
},
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>