import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client/core';
const BACKEND_URL = "https://backend.meander.software"; // Backend URL new

const apolloClient = new ApolloClient({
  link: new HttpLink({
    uri: `${BACKEND_URL}/graphql`,
  }),
  cache: new InMemoryCache(),
});

export default apolloClient;
