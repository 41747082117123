
<template>
  <div class="app-about-area pb-100 pt-100 ">
      <div class="container-fluid">
          <div class="row align-items-center">
              <div class="col-lg-6 col-md-12">
                  <div class="app-about-image">
                    <img v-if="data?.circle_image?.url"
     :src="$backendUrl + data?.circle_image?.url" loading="lazy"
     alt="image">

    <img v-else 
         src="../../assets/images/more-home/about/about.png" 
         alt="fallback image">

                  </div>
              </div>

              <div class="col-lg-6 col-md-12">
                  <div class="app-about-content">
                      <div class="big-text">About App</div>
                      <span class="sub-title text-uppercase  ">About App</span>
                      <h2>{{data?.about_app?.title}}</h2>
                      <p>{{ data?.about_app?.description || 
            "Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began."
            }}</p>
                      <ul class="list">
                          <li>
                              <div class="icon">
                                  <i class="ri-award-line"></i>
                              </div>
                              <h3>{{data?.about_app?.additional_info[0]?.title}}</h3>
                              <p>{{ data?.about_app?.additional_info[0]?.description}}</p>

                          </li>
                          <li>
                              <div class="icon bg2">
                                  <i class="ri-download-cloud-2-line"></i>
                              </div>
                              <h3>{{data?.about_app?.additional_info[1]?.title}}</h3>
                              <p>{{ data?.about_app?.additional_info[1]?.description }}</p>

                          </li>
                      </ul>
                      <div class="btn-box">
                           <button @click="openCalendly" class="default-btn">Schedule Demo</button>
                          <!-- <router-link to="/features-one" class="link-btn">See All Features</router-link> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="app-about-shape-1">
          <img src="../../assets/images/more-home/about/shape-1.png" alt="image">
      </div>
      <div class="app-about-shape-2">
          <img src="../../assets/images/more-home/about/shape-2.png" alt="image">
      </div>
      <div class="app-about-shape-3">
          <img src="../../assets/images/more-home/about/shape-3.png" alt="image">
      </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    openCalendly() {
      // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
      return false;
    },
  }
}
</script>

