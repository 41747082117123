<template>
    <div class="page-title-area mb-40px">
        <div class="container">
            <div class="page-title-content">
                <h2>{{ data?.pageTitle || 'Our Page' }}</h2>
                <!-- <ul>
                    <li style="list-style-type: none;">
                        <router-link to="/">Home</router-link>
                    </li>
                    <li>attributes</li>
                </ul> -->
            </div>
        </div>
        <div class="divider" ></div>
        <div class="lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
        <div class="banner-shape1">
            <img src="../../assets/images/shape/shape9.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageTitle',
    props:{
        
        data: {
      type: [], // Set the prop type to be Any
      required: true,
    },
    },
    
    }
</script>