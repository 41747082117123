<!-- <template>
    <div class="funfacts-area pb-75 pt-75">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="funfacts-box">
                        <div class="icon">
                            <i class="ri-download-2-line"></i>
                        </div>
                        <p>Total Experience</p>
                        <h3>9+ Years</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6" title="98% Job Success">
                    <div class="funfacts-box bg1">
                        <div class="icon">
                            <i class="ri-star-fill"></i>
                        </div>
                        <p>Upwork 98%</p>
                        <h3 >Success</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="funfacts-box bg2">
                        <div class="icon">
                            <i class="ri-global-line"></i>
                        </div>
                        <p>Projects Developed</p>
                        <h3>500+</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="funfacts-box bg3">
                        <div class="icon">
                            <i class="ri-map-pin-user-line"></i>
                        </div>
                        <p>Team Size</p>
                        <h3>100+</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template> -->
<template>
    <div class="features-area pb-75 pt-75">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                    <div class="features-box">
                        <div class="icon">
                            <i class="ri-star-line"></i>
                        </div>
                        <!-- <p>Total Experience</p> -->
                        <h3>9+ Years Experience </h3>
                        <!-- <h3>Total Experience</h3> -->

                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                    <div class="features-box">
                        <div class="icon bg2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M18.561 13.158c-1.102 0-2.135-.467-3.074-1.227l.228-1.076l.008-.042c.207-1.143.849-3.06 2.839-3.06a2.705 2.705 0 0 1 2.703 2.703a2.707 2.707 0 0 1-2.704 2.702m0-8.14c-2.539 0-4.51 1.649-5.31 4.366c-1.22-1.834-2.148-4.036-2.687-5.892H7.828v7.112a2.551 2.551 0 0 1-2.547 2.548a2.55 2.55 0 0 1-2.545-2.548V3.492H0v7.112c0 2.914 2.37 5.303 5.281 5.303c2.913 0 5.283-2.389 5.283-5.303v-1.19c.529 1.107 1.182 2.229 1.974 3.221l-1.673 7.873h2.797l1.213-5.71c1.063.679 2.285 1.109 3.686 1.109c3 0 5.439-2.452 5.439-5.45c0-3-2.439-5.439-5.439-5.439"/></svg>
                        </div>
                        <!-- <p>Success</p> -->
                        <h3>Upwork: 98% Success</h3>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                    <div class="features-box">
                        <div class="icon bg3">
                            <i class="ri-draft-fill"></i>
                        </div>
                        <!-- <p>Projects Developed</p> -->
                        <h3>  500+ Projects Delivered   </h3>
                       
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                    <div class="features-box">
                        <div class="icon bg4">
                            <i class="ri-team-line"></i>
                        </div>
                        <!-- <p>Team Size</p> -->
                        <h3>  Team: 100+</h3>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'Funfacts'
}
</script>