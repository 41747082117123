<template>
    <div class="team-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span class="sub-title text-uppercase  ">OUR EXPERTS</span>
                <h2>Meet With Our Team Member</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="">
                            <div class="rounded-circle overflow-hidden"
                               
                                 ><img src='../../../assets/images/ourteam/aa.png' alt='image'/>
                            </div>
                        </div>
                        <div class="content">
                            <h3>Founder & CEO </h3>
                            <span>18 year Experience</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="">
                            <div class="rounded-circle overflow-hidden"
                               
                               ><img src='../../../assets/images/ourteam/bb.png' alt='image'/>
                          </div>
                        </div>
                        <div class="content">
                            <h3>Co-Founder </h3>
                            <span>15 Year Experience </span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="">
                            <div class="rounded-circle overflow-hidden"
                               
                                 ><img src='../../../assets/images/ourteam/cc.png' alt='image'/>
                            </div>
                        </div>
                        <div class="content">
                            <h3>Director</h3>
                            <span>10 year Experience</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="">
                            <div class="rounded-circle overflow-hidden"
                               
                                 ><img src='../../../assets/images/ourteam/dd.png' alt='image'/>
                            </div>
                        </div>
                        <div class="content">
                            <h3>HR</h3>
                            <span> 8 year Experience</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="">
                            <div class="rounded-circle overflow-hidden"
                               
                               ><img src='../../../assets/images/ourteam/ee.png' alt='image'/>
                          </div>
                        </div>
                        <div class="content">
                            <h3>Project Manager</h3>
                            <span> 7 year Experience</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="rounded-circle" >
                            <div class="rounded-circle overflow-hidden"
                               
                                 ><img src='../../../assets/images/ourteam/ff.png' alt='image'/>
                            </div>
                        </div>
                        <div class="content">
                            <h3>Project Manager</h3>
                            <span> 7 year Experience</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurTeam'
}
</script>