<template>
    <div class="funfacts-area ptb-100">
        <div class="container">
            <div class="row justify-content-center m-0">

                <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6" v-for="(item, index) in data[0]?.attributes?.ms_industries_credentials_stats?.data"  :key="item.id">
                    <div :class="getbg(index)" class="features-box">
                        <div class="icon">
                            <i :class="getIcon(index)"></i>
                        </div>
                        <p>{{item?.attributes?.name||"Tota Experience"}}</p>
                        <h3> {{ item?.attributes?.title }}</h3>
                        <!-- <h3>Total Experience</h3> -->

                    </div>
                </div>
<!-- 
                <div class="col-lg-3 col-sm-6 col-md-6 p-0" v-for="(item, index) in data[0]?.attributes?.ms_industries_credentials_stats?.data"  :key="item.id">
                    <div :class="getbg(index)" class="funfacts-box bg1 mb-0">
                        <div class="icon">
                            <i :class="getIcon(index)"></i>
                        </div>
                        <p>{{item?.attributes?.name||"Tota Experience"}}</p>
                        <h5 class="text-white"> {{ item?.attributes?.title }}</h5>
                    </div>
                </div> -->
                <!-- <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="funfacts-box bg1 mb-0">
                        <div class="icon">
                            <i class="ri-star-fill"></i>
                        </div>
                        <p> Upwork 98%</p>
                        <h3>Success</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="funfacts-box bg2 mb-0">
                        <div class="icon">
                            <i class="ri-global-line"></i>
                        </div>
                        <p>Projects Developed</p>
                        <h3>500+</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="funfacts-box bg3 mb-0">
                        <div class="icon">
                            <i class=""></i>
                        </div>
                        <p>Team Size</p>
                        <h3>100+</h3>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IndustriesStats',
    props:{
        data:[]
    },
    methods:{
        getIcon(index) {
        const icons = [
            'ri-download-2-line',
            'ri-star-fill',
            'ri-global-line',
            'ri-map-pin-user-line'
            // Add more icons as needed
        ];

        // Return the icon based on the index or a default icon if the index is out of bounds
        return icons[index] || 'default-icon-class';
    },
        getbg(index) {
        const bg = [
               "bg3",    
            'bg1',
            'bg2',
            'bg3'
             
            // Add more bg as needed
        ];

        // Return the icon based on the index or a default icon if the index is out of bounds
        return bg[index] || '';
    },
    }
}
</script>