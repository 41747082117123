<template>
    <div class="app-progress-area bg-black ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="app-progress-animation-image" :style="{ paddingBottom: '0px' }">
                        <img src="../../assets/images/app-progress/Opportunities-&-Job-Placement.png" alt="app-progress" data-aos="fade-down" data-aos-duration="1000">

                        <!-- <img src="../../assets/images/app-progress/app-progress1.png" alt="app-progress" data-aos="fade-down" data-aos-duration="1000">
                        <img src="../../assets/images/app-progress/app-progress2.png" alt="app-progress" data-aos="fade-right" data-aos-duration="1000">
                        <img src="../../assets/images/app-progress/app-progress3.png" alt="app-progress" data-aos="fade-left" data-aos-duration="1000">
                        <img src="../../assets/images/app-progress/app-progress4.png" alt="app-progress" data-aos="fade-up" data-aos-duration="1000"> -->
                        <img src="../../assets/images/app-progress/progress-main.png" class="main-image" alt="app-progress">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="app-progress-content text-white">
                        <span class="sub-title text-uppercase  ">{{ 'Shape Your Futures'}}</span>
                        <h2>{{ 'Opportunities & Job Placement'}}</h2>
                        <p>{{"At Meander Software, we have many exciting opportunities and jobs for both experienced professionals and students. Our expert developers are known for getting things done quickly and affordably. If you're a developer looking to push your skills and work on innovative projects, this is the place for you. We maintain competitive prices without sacrificing quality, setting new standards in the industry. If you're a student, we offer training programs with guidance from experienced professionals. Join our Meander family to unlock your potential and explore job openings, kickstarting your career journey with us."}}</p>
                        <!-- <p>Most provabily best  for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy  backup just log in with your mail account from.</p> -->
                      
                        <button  class="default-btn">Get Started</button>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TechnologiesChallengesAndTrack',
    props:{
         data: {
      type: [], // Set the prop type to be Any
      required: true,
    },
    },
    methods: {
      openCalendly() {
      // eslint-disable-next-line no-undef
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>