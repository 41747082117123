<template>
    <div class="app-download-area  pb-5">
        <div class="container">
            <div class="app-download-inner bg-gray">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="app-download-content">
                            <span class="sub-title text-uppercase  ">{{schedule_demo?.title ||"Schedule Demo"}}</span>
                            <h2>{{"Experience Our Product in Real Time"}}</h2>
                            <p>{{schedule_demo?.description ||"Unlock the full potential of our solution with a personalized demo. Schedule your session today and discover how our product can transform your experience."}}</p>
                          
                        <button @click="openCalendly" class="default-btn">Schedule Demo</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="app-download-image" data-aos="fade-up">
                            <img v-if="this.data && schedule_demo?.image?.url" 
     :src="$backendUrl + schedule_demo?.image?.url" 
     alt="image" loading="lazy" />
                            <img v-else src="../../assets/images/app/app-img4.png" alt="app-img" loading="eager" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppDownload',
    props: {
 
        schedule_demo: {
   type: Object,
   required: true,
   default: () => ({ title: "", description: "", image: { image: {url:""} } }),
}
},
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
}
}
</script>